// React
import { useState, useEffect } from "react";

// Material
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  ListItemIcon,
  MenuItem,
} from "@mui/material";

// Date fns
import { parse, format } from "date-fns";
import { es } from "date-fns/locale";

// Theming
import { useTheme } from "@mui/material/styles";

// Axios
import { confirmandSelf, attendances } from "assets/plugins/axios";

// User
import useUser from "assets/hooks/useUser";

// Charts
import { PieChart, Pie, ResponsiveContainer, Legend, Cell } from "recharts";

// Constants
import { attendanceTypes } from "assets/constants";

// Functions
import { getLabel, switchIcons } from "assets/functions";

// Components
import Alert from "components/Alert";
import Loading from "components/Loading";
import Error from "components/Error";
import CollapseSection from "components/CollapseSection";

// Log
import { logger } from "assets/plugins/log";

const ConfirmandDashboard = () => {
  const { currentUser } = useUser();

  const [ConfirmandState, setConfirmandState] = useState({});
  const [InitialLoadingState, setInitialLoadingState] = useState(true);
  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });
  const [AttendanceLoadingState, setAttendanceLoadingState] = useState(false);
  const [AttendanceState, setAttendanceState] = useState([]);

  const fieldsMap = {
    id: {
      fieldName: "id",
      expand: "",
    },
    "user.id": {
      fieldName: "user.id",
      expand: "user",
    },
    mass_attendance: {
      fieldName: "mass_attendance",
      expand: "",
    },
    catechesis_attendance: {
      fieldName: "catechesis_attendance",
      expand: "",
    },
  };

  const initialFields = [];
  const initialExpand = [];

  for (const field of Object.values(fieldsMap)) {
    initialFields.push(field.fieldName);
    if (!initialExpand.includes(field.expand)) {
      initialExpand.push(field.expand);
    }
  }

  const params = {
    fields: initialFields.toString(),
    expand: initialExpand.toString(),
  };

  useEffect(() => {
    const getConfirmand = async () => {
      try {
        const { data: response } = await confirmandSelf({
          token: currentUser.token,
          params,
        });

        const data = response.results[0];

        const catechesisAttendance = Object.entries(data.catechesis_attendance)
          .map(([key, value]) => {
            return {
              name: getLabel(attendanceTypes, key),
              value: value,
            };
          })
          .sort((a, b) => (b.name > a.name ? -1 : 1));

        const massAttendance = Object.entries(data.mass_attendance)
          .map(([key, value]) => {
            return {
              name: getLabel(attendanceTypes, key),
              value: value,
            };
          })
          .sort((a, b) => (b.name > a.name ? -1 : 1));

        setConfirmandState({
          user_id: data.user.id,
          id: data.id,
          catechesisAttendance,
          massAttendance,
          imageUrl: `https://api.dicebear.com/7.x/bottts/svg?seed=${data.user.uid}`,
        });
        setInitialLoadingState(false);
      } catch (error) {
        setErrorState({
          status: true,
          message: error.message,
        });
        setInitialLoadingState(false);
      }
    };
    getConfirmand();
    logger("[Confirmand Dashboard] page loaded.", {
      payload: { currentUser },
      source: "/confirmand/dashboard",
    });
  }, []);

  const onAttendanceClick = async () => {
    try {
      setAttendanceLoadingState(true);
      const { data } = await attendances({
        token: currentUser.token,
        params: {
          search: ConfirmandState.user_id,
          fields:
            "id,attendance,comments,activity.date,activity.activity_type,activity.id",
          expand: "activity",
        },
      });
      const formattedAttendances = data.results
        .map((attendance) => {
          return {
            id: attendance.id,
            date: parse(
              attendance.activity.date,
              "yyyy-MM-dd'T'HH:mm:ssxxx",
              new Date(),
            ),
            activity_type: attendance.activity.activity_type,
            attendance: attendance.attendance,
            comments: attendance.comments,
          };
        })
        .sort((a, b) => (b.date > a.date ? -1 : 1));
      setAttendanceState(formattedAttendances);
    } catch (error) {
      setErrorState({
        open: true,
        message: `${error}. Intente de nuevo.`,
      });
    } finally {
      setAttendanceLoadingState(false);
    }
  };

  const theme = useTheme();

  if (InitialLoadingState) return <Loading />;
  if (ErrorState.status) return <Error errorMessage={ErrorState.message} />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={0} style={{ padding: "1%" }}>
          <Typography variant="h1">Dashboard</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={0} style={{ padding: "3%" }}>
          <Typography variant="h3" paddingBottom="1%">
            Catequesis
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Legend verticalAlign="bottom" />
              <Pie
                data={ConfirmandState.catechesisAttendance}
                legendType="line"
                innerRadius={50}
                dataKey="value"
                nameKey="name"
              >
                <Cell fill={theme.palette.success.dark} />
                <Cell fill={theme.palette.error.dark} />
                <Cell fill={theme.palette.info.dark} />
                <Cell fill={theme.palette.warning.dark} />
                <Cell fill={theme.palette.warning.main} />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={0} style={{ padding: "3%" }}>
          <Typography variant="h3" paddingBottom="1%">
            Misas
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Legend verticalAlign="bottom" />
              <Pie
                data={ConfirmandState.massAttendance}
                legendType="line"
                innerRadius={50}
                dataKey="value"
                nameKey="name"
              >
                <Cell fill={theme.palette.success.dark} />
                <Cell fill={theme.palette.error.dark} />
                <Cell fill={theme.palette.info.dark} />
                <Cell fill={theme.palette.warning.dark} />
                <Cell fill={theme.palette.warning.main} />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} style={{ padding: "1%" }}>
          <CollapseSection
            title="Listado de asistencias"
            loading={AttendanceLoadingState}
            onClick={onAttendanceClick}
            defaultExpanded={false}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Asistencia</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {AttendanceState.map((attendance) => (
                    <TableRow key={attendance.id}>
                      <TableCell>{attendance.activity_type}</TableCell>
                      <TableCell>
                        {format(attendance.date, "dd 'de' MMMM", {
                          locale: es,
                        })}
                      </TableCell>
                      <TableCell>
                        <MenuItem>
                          <ListItemIcon>
                            {switchIcons(attendance.attendance)}
                          </ListItemIcon>
                          {getLabel(attendanceTypes, attendance.attendance)}
                        </MenuItem>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CollapseSection>
        </Paper>
      </Grid>
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

export default ConfirmandDashboard;
