// Material
import { Grid } from "@mui/material";
import { Route, Switch, Redirect } from "react-router-dom";

//Context
import useUser from "assets/hooks/useUser";

//Routing
import { routes } from "components/Routes";

// Components
import { AdminNavbar } from "components/Navbars";
import { AdminFooter } from "components/Footers";

const AdminLayout = () => {
  const { currentUser } = useUser();

  const getRoutes = (routes) => {
    const children = routes.find((route) => route.path === "/admin").children;

    const filteredRoutes = children.filter((route) =>
      route.permissions.every((permission) =>
        currentUser.claims.permissions.includes(permission),
      ),
    );

    return filteredRoutes.map((route, key) => (
      <Route
        path={"/admin/" + route.path}
        component={route.element}
        key={key}
      />
    ));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <AdminNavbar />
      </Grid>
      <Grid item xs={12} padding="3%" bgcolor="background.neutral">
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
      </Grid>
      <Grid item xs={12}>
        <AdminFooter />
      </Grid>
    </Grid>
  );
};

export default AdminLayout;
