// Prop Types
import PropTypes from "prop-types";

// Material
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";

// Routing
import { useHistory } from "react-router-dom";
import { routes } from "components/Routes";

// Functions
import { isCatechesisDay } from "assets/functions";

// Hooks
import useUser from "assets/hooks/useUser";

const AdminDrawer = ({ open, onClose, logo }) => {
  const history = useHistory();
  const { currentUser } = useUser();

  const getLinks = (routes) => {
    const userPermissions = currentUser.claims.permissions;
    const children = routes.find((route) => route.path === "/admin").children;
    const filteredChildren = children.filter((route) =>
      route.permissions.every((permission) =>
        userPermissions.includes(permission),
      ),
    );

    const drawerChildren = filteredChildren.filter((route) => {
      if (route.path === "late") {
        return isCatechesisDay(currentUser.claims.level);
      }
      return route.drawer;
    });

    const handleItemClick = (route) => {
      history.push("/admin/" + route.path);
      onClose();
    };

    return drawerChildren.map((route, key) => {
      return (
        <ListItem
          onClick={() => handleItemClick(route)}
          button
          key={key}
          name={route.name}
        >
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText>{route.name}</ListItemText>
        </ListItem>
      );
    });
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <List style={{ padding: "1rem" }}>
        <img
          src={logo}
          alt="Logo de la Confirmacion Santa Marta"
          width="200rem"
        />
        <Divider />
        {getLinks(routes)}
      </List>
    </Drawer>
  );
};

AdminDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  logo: PropTypes.string.isRequired,
};

export default AdminDrawer;
