const activityTypes = [
  {
    label: "Misa",
    value: "mass",
  },
  {
    label: "Catequesis",
    value: "catechesis",
  },
  {
    label: "Reunión",
    value: "meeting",
  },
];

export default activityTypes;
