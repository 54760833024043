//React
import { useEffect, useState } from "react";

// Firebase
import { logOut } from "assets/plugins/firebase/auth";

// Components
import Error from "components/Error";

// User
import useUser from "assets/hooks/useUser";

// Routing
import { useHistory } from "react-router-dom";

// Log
import { logger } from "assets/plugins/log";

const Logout = () => {
  const history = useHistory();

  const { currentUser } = useUser();

  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });

  useEffect(() => {
    logger("[Logout] page loaded.", {
      payload: { currentUser },
      source: "/auth/logout",
    });
  }, []);

  useEffect(() => {
    const logOutUser = async () => {
      try {
        await logOut();
        history.push("/auth/login");
      } catch (error) {
        setErrorState({
          status: true,
          message: error.message,
        });
      }
    };

    logOutUser();
  }, []);

  if (ErrorState.status) {
    return <Error errorMessage={ErrorState.message} />;
  }

  return null;
};

export default Logout;
