// Prop types
import PropTypes from "prop-types";

//Material
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

// Routing
import { useHistory } from "react-router-dom";

// Date fns
import { parse, format } from "date-fns";
import { es } from "date-fns/locale";

const dateFormat = (row) => {
  return format(
    parse(row.value, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
    "dd 'de' MMMM",
    { locale: es },
  );
};

const timeFormat = (row) => {
  return format(
    parse(row.value, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
    "hh:mm a",
    { locale: es },
  );
};

const columns = [
  {
    field: "id",
    hideable: false,
  },
  {
    field: "type",
    headerName: "Tipo",
    type: "string",
    width: 150,
  },
  {
    field: "date",
    headerName: "Fecha",
    type: "string",
    width: 150,
    valueGetter: (params) => params.row.date,
    valueFormatter: dateFormat,
  },
  {
    field: "time",
    headerName: "Hora",
    type: "string",
    width: 100,
    valueGetter: (params) => params.row.date,
    valueFormatter: timeFormat,
  },
  {
    field: "comments",
    headerName: "Comentarios",
    type: "string",
    width: 600,
  },
];

const AttendancesTable = ({ rows }) => {
  const history = useHistory();

  const pageSize = Math.floor(window.innerHeight * 0.014);

  const handleOnCellClick = (event) => {
    history.push(`/admin/attendance/${event.row.id}`, { type: event.row.type });
  };

  return (
    <DataGrid
      onCellClick={handleOnCellClick}
      disableRowSelectionOnClick
      disableColumnMenu
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      initialState={{ columns: { columnVisibilityModel: { id: false } } }}
      slots={{ toolbar: GridToolbar }}
      slotProps={{ toolbar: { showQuickFilter: true } }}
      style={{
        backgroundColor: "white",
        height: "90vh",
      }}
    />
  );
};

AttendancesTable.propTypes = { rows: PropTypes.array.isRequired };

export default AttendancesTable;
