const tshirtSizes = [
  {
    label: "16",
    value: "16",
  },
  {
    label: "18",
    value: "18",
  },
  {
    label: "20",
    value: "20",
  },
  {
    label: "XS",
    value: "xs",
  },
  {
    label: "S",
    value: "s",
  },
  {
    label: "M",
    value: "m",
  },
  {
    label: "L",
    value: "l",
  },
  {
    label: "XL",
    value: "xl",
  },
  {
    label: "XXL",
    value: "xxl",
  },
  {
    label: "XXXL",
    value: "xxxl",
  },
];

export default tshirtSizes;
