//React
import { useState, useEffect } from "react";

//Router
import { useHistory } from "react-router-dom";

// Material
import {
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  useTheme,
} from "@mui/material";

//Firebase
import { passwordReset } from "assets/plugins/firebase/auth";

// Components
import Alert from "components/Alert";

// Log
import { logger } from "assets/plugins/log";

const PasswordReset = () => {
  const logo = require("assets/images/logo.svg").default;

  const [EmailState, setEmailState] = useState("");
  const [BadResetState, setBadResetState] = useState(false);
  const [ErrorMessageState, setErrorMessageState] = useState({
    status: false,
    message: "",
  });
  const [LoadingState, setLoadingState] = useState(false);

  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    logger("[Reset password] page loaded.", { source: "/auth/resetpassword" });
  }, []);

  const handleResetClick = async (event) => {
    event.preventDefault();
    setLoadingState(true);
    try {
      await passwordReset(EmailState);
      history.replace("/auth/recoverysuccessful");
    } catch (error) {
      setBadResetState(true);
      setErrorMessageState({
        status: true,
        message: `${error.message} Intente de nuevo.`,
      });
      setLoadingState(false);
    }
  };

  const handleCancelClick = () => history.replace("/auth/login");

  const handleEmailChange = (event) => {
    setEmailState(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleResetClick(event);
    }
  };

  return (
    <Grid container spacing={2} padding="5%" style={{ height: "100%" }}>
      <Grid item xs={12} md={7}>
        <Paper
          elevation={0}
          style={{
            height: "100%",
            padding: "5%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: theme.palette.background.neutral,
          }}
        >
          <img
            src={logo}
            alt="Logo de la Confirmación de la Parroquia Santa Marta"
            width="50%"
          />
          <Typography variant="h1">CONFI SM</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={5}>
        <Paper
          elevation={0}
          style={{
            height: "100%",
            padding: "5%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.background.neutral,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">Reestablecer contraseña</Typography>
              <Typography variant="body1">
                Ingresa tu correo para reestablecer tu contraseña
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={EmailState}
                onChange={handleEmailChange}
                onKeyDown={handleKeyPress}
                autoComplete="off"
                type="email"
                placeholder="Correo electrónico"
                label="Correo electrónico"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} paddingTop="5%">
            <Grid item xs={4}>
              <Button
                variant="outlined"
                color="black"
                fullWidth
                onClick={(e) => handleCancelClick(e)}
                disabled={LoadingState}
              >
                CANCELAR
              </Button>
            </Grid>
            <Grid item xs={8}>
              <Button
                variant="contained"
                fullWidth
                onClick={(e) => handleResetClick(e)}
                disabled={LoadingState}
              >
                ENVIAR
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Alert
        open={BadResetState}
        onClose={() => setBadResetState(false)}
        message={ErrorMessageState.message}
        severity="error"
      />
    </Grid>
  );
};

export default PasswordReset;
