// Material
import { Grid } from "@mui/material";

//Routing
import { Route, Switch, Redirect } from "react-router-dom";
import { routes } from "components/Routes";

// Components
import { ConfirmandNavbar } from "components/Navbars";
import { ConfirmandFooter } from "components/Footers";

const ConfirmandLayout = () => {
  const getRoutes = (routes) => {
    const children = routes.find(
      (route) => route.path === "/confirmand",
    ).children;

    return children.map((route, key) => (
      <Route
        path={"/confirmand/" + route.path}
        component={route.element}
        key={key}
      />
    ));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <ConfirmandNavbar />
      </Grid>
      <Grid item xs={12} padding={"3%"} bgcolor="background.neutral">
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/confirmand/dashboard" />
        </Switch>
      </Grid>
      <Grid item xs={12}>
        <ConfirmandFooter />
      </Grid>
    </Grid>
  );
};

export default ConfirmandLayout;
