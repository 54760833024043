//React
import { useState, useEffect } from "react";

// Material
import { Paper, Grid, Typography, Button } from "@mui/material";

//Routing
import { useHistory } from "react-router-dom";

//Components
import ActivitiesTable from "components/ActivitiesTable";
import Alert from "components/Alert";
import Error from "components/Error";
import Loading from "components/Loading";

// Axios
import { activities } from "assets/plugins/axios";

//User
import useUser from "assets/hooks/useUser";

// Log
import { logger } from "assets/plugins/log";

const AdminActivities = () => {
  const { currentUser } = useUser();

  const history = useHistory();

  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });
  const [LoadingState, setLoadingState] = useState(true);
  const [ActivitiesState, setActivitiesState] = useState([]);

  useEffect(() => {
    const getActivities = async () => {
      const params = { level: currentUser.claims.level };
      // TODO: Are we using this constraint?
      if (!currentUser.claims.permissions.includes("view_meeting"))
        params.excludeMeetings = "";
      const { data } = await activities({
        token: currentUser.token,
        params: params,
      });
      const levelActivities = data.results.map((activity) => ({
        id: activity.id,
        type: activity.activity_type,
        date: activity.date,
        attendance: activity.has_attendance,
        comments: activity.comments,
      }));
      setActivitiesState(levelActivities);
    };

    try {
      getActivities();
      setLoadingState(false);
      logger("[Admin Activities] page loaded.", {
        payload: { currentUser },
        source: "/admin/activities",
      });
    } catch (error) {
      setErrorState({
        status: true,
        message: error.message,
      });
    }
  }, [
    currentUser.claims.level,
    currentUser.claims.permissions,
    currentUser.token,
  ]);

  if (LoadingState) return <Loading />;
  if (ErrorState.status) return <Error />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={0} style={{ padding: "1%" }}>
          <Typography variant="h1">Actividades</Typography>
        </Paper>
      </Grid>
      {currentUser.claims.permissions.includes("add_activity") ? (
        <Grid item xs={12} textAlign="end">
          <Button
            variant="contained"
            onClick={() => history.push("/admin/activities/add")}
          >
            AGREGAR ACTIVIDAD
          </Button>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <ActivitiesTable rows={ActivitiesState} />
      </Grid>
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

export default AdminActivities;
