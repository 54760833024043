// React
import { useState } from "react";

// Material
import { AppBar, Toolbar, IconButton, Avatar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

// Components
import { ConfirmandDrawer } from "./Drawers";
import { ConfirmandMenu } from "./Menus";

// Current user
import useUser from "assets/hooks/useUser";

const ConfirmandNavbar = () => {
  const { currentUser } = useUser();

  const [DrawerState, setDrawerState] = useState(false);
  const [AnchorState, setAnchorState] = useState(null);
  const [MenuState, setMenuState] = useState(false);

  const logo = require("assets/images/logo.svg").default;

  const handleMenuClose = () => {
    setMenuState(false);
    setAnchorState(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorState(event.currentTarget);
    setMenuState(true);
  };

  return (
    <AppBar position="sticky" color="primary">
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton onClick={() => setDrawerState(true)}>
          <MenuIcon color="white" />
        </IconButton>
        <IconButton onClick={handleMenuOpen}>
          <Avatar alt="Avatar" src={currentUser?.photoURL} />
        </IconButton>
      </Toolbar>
      <ConfirmandMenu
        anchor={AnchorState}
        open={MenuState}
        onClose={handleMenuClose}
      />
      <ConfirmandDrawer
        logo={logo}
        open={DrawerState}
        onClose={() => setDrawerState(false)}
      />
    </AppBar>
  );
};

export default ConfirmandNavbar;
