import { storage } from "./index";
import {
  connectStorageEmulator,
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

if (process.env.REACT_APP_ENV === "cypress") {
  connectStorageEmulator(storage, "firebase", 9199);
}

if (process.env.REACT_APP_ENV === "development") {
  connectStorageEmulator(storage, "localhost", 9199);
}

export const updateUserProfileImageStorage = (image, userUID) =>
  uploadBytes(ref(storage, `users/${userUID}/profilePicture`), image);

export const updateUserBaptismCertificateStorage = (image, userUID) =>
  uploadBytes(ref(storage, `users/${userUID}/baptismCertificate`), image);

export const getUserProfileImageDownloadURL = (userUID) =>
  getDownloadURL(ref(storage, `users/${userUID}/profilePicture`));

export const getUserBaptismCertificateDownloadURL = (userUID) =>
  getDownloadURL(ref(storage, `users/${userUID}/baptismCertificate`));
