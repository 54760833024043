const getBiologicalParents = (relationships) => {
  const biologicalParentPlaceholder = {
    id: "",
    user_id: "",
    user_uid: "",
    first_name: "NO VACIO",
    second_name: "NO VACIO",
    last_name: "NO VACIO",
    second_last_name: "NO VACIO",
    cellphone: "",
    email: "",
  };

  const biologicalParents = {
    mother: { ...biologicalParentPlaceholder },
    father: { ...biologicalParentPlaceholder },
  };

  const remainingRelationships = {};

  relationships.forEach((relationship) => {
    if (relationship.relationship === "mother") {
      biologicalParents.mother.id = relationship.guardian.id;
      biologicalParents.mother.user_id = relationship.guardian.user.id;
      biologicalParents.mother.user_uid = relationship.guardian.user.uid;
      biologicalParents.mother.first_name =
        relationship.guardian.user.first_name;
      biologicalParents.mother.second_name =
        relationship.guardian.user.second_name;
      biologicalParents.mother.last_name = relationship.guardian.user.last_name;
      biologicalParents.mother.second_last_name =
        relationship.guardian.user.second_last_name;
      biologicalParents.mother.cellphone = relationship.guardian.user.cellphone;
      biologicalParents.mother.email = relationship.guardian.user.email;
      biologicalParents.mother.sex = relationship.guardian.user.sex;
    } else if (relationship.relationship === "father") {
      biologicalParents.father.id = relationship.guardian.id;
      biologicalParents.father.user_id = relationship.guardian.user.id;
      biologicalParents.father.user_uid = relationship.guardian.user.uid;
      biologicalParents.father.first_name =
        relationship.guardian.user.first_name;
      biologicalParents.father.second_name =
        relationship.guardian.user.second_name;
      biologicalParents.father.last_name = relationship.guardian.user.last_name;
      biologicalParents.father.second_last_name =
        relationship.guardian.user.second_last_name;
      biologicalParents.father.cellphone = relationship.guardian.user.cellphone;
      biologicalParents.father.email = relationship.guardian.user.email;
      biologicalParents.father.sex = relationship.guardian.user.sex;
    } else {
      const guardian = {
        id: relationship.guardian.id,
        user_id: relationship.guardian.user.id,
        user_uid: relationship.guardian.user.uid,
        first_name: relationship.guardian.user.first_name,
        second_name: relationship.guardian.user.second_name,
        last_name: relationship.guardian.user.last_name,
        second_last_name: relationship.guardian.user.second_last_name,
        cellphone: relationship.guardian.user.cellphone,
        email: relationship.guardian.user.email,
        sex: relationship.guardian.user.sex,
        relationship: relationship.relationship,
        relationship_id: relationship.id,
      };
      remainingRelationships[relationship.id] = guardian;
    }
  });

  return {
    biologicalParents,
    remainingRelationships,
  };
};

export default getBiologicalParents;
