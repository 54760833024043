const relationships = [
  {
    value: "father",
    label: "Padre",
  },
  {
    value: "mother",
    label: "Madre",
  },
  {
    value: "grandfather",
    label: "Abuelo",
  },
  {
    value: "grandmother",
    label: "Abuela",
  },
  {
    value: "brother",
    label: "Hermano",
  },
  {
    value: "sister",
    label: "Hermana",
  },
  {
    value: "uncle",
    label: "Tío",
  },
  {
    value: "aunt",
    label: "Tía",
  },
  {
    value: "cousin",
    label: "Primo",
  },
  {
    value: "godfather",
    label: "Padrino",
  },
  {
    value: "godmother",
    label: "Madrina",
  },
  {
    value: "foster_father",
    label: "Padre adoptivo",
  },
  {
    value: "foster_mother",
    label: "Madre adoptiva",
  },
  {
    value: "step_father",
    label: "Padrastra",
  },
  {
    value: "step_mother",
    label: "Madrastra",
  },
  {
    value: "other",
    label: "Otro",
  },
];

export default relationships;
