// Prop Types
import PropTypes from "prop-types";

//React Router
import { Route, Redirect } from "react-router-dom";

//Context
import useUser from "assets/hooks/useUser";
import Loading from "components/Loading";

const CustomRoute = ({
  component: Component,
  catechist,
  isPublic,
  ...rest
}) => {
  const { currentUser, pending } = useUser();

  const isValid =
    isPublic ||
    (!!currentUser &&
      catechist === (currentUser.claims.user_type === "catechist"));

  if (pending) {
    return <Loading />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isValid ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};

CustomRoute.propTypes = {
  component: PropTypes.func.isRequired,
  catechist: PropTypes.bool,
  isPublic: PropTypes.bool,
};

export default CustomRoute;
