// Prop Types
import PropTypes from "prop-types";

// React
import { useState, Fragment } from "react";

// Material
import {
  Grid,
  List,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Menu,
  MenuItem,
  Button,
  Divider,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

// Functions
import { switchIcons } from "assets/functions";

const AttendanceList = ({
  attendances,
  onMenuClick,
  onMenuItemClick,
  onSave,
  activityType,
  extraTypes,
}) => {
  const [SelectedItems, setSelectedItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const attendanceTypes = {
    Misa: [
      {
        label: "Asistencia",
        value: "attendance",
      },
      {
        label: "Ausencia",
        value: "absence",
      },
      {
        label: "Tardanza",
        value: "late",
      },
    ],
    Catequesis: [
      {
        label: "Asistencia",
        value: "attendance",
      },
      {
        label: "Ausencia",
        value: "absence",
      },
      {
        label: "Tardanza",
        value: "late",
      },
    ],
    Reunión: [
      {
        label: "Asistencia",
        value: "attendance",
      },
      {
        label: "Ausencia",
        value: "absence",
      },
      {
        label: "Tardanza",
        value: "late",
      },
      {
        label: "Ausencia Justificada",
        value: "excusedabsence",
      },
    ],
  };

  if (extraTypes) {
    attendanceTypes["Catequesis"].push({
      label: "Ausencia Justificada",
      value: "excusedabsence",
    });
    attendanceTypes["Misa"].push({
      label: "Ausencia Justificada",
      value: "excusedabsence",
    });
  }

  const handleCheckboxClick = (event, item) => {
    if (event.target.checked) {
      setSelectedItems([...SelectedItems, item]);
    } else {
      setSelectedItems(
        SelectedItems.filter((selectedItem) => selectedItem.id !== item.id),
      );
    }
  };

  const handleSaveClick = () => {
    onSave(SelectedItems);
  };

  const handleMenuClick = (event, attendance) => {
    onMenuClick(attendance);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (type) => {
    onMenuItemClick(type);
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <List style={{ backgroundColor: "white" }}>
          {attendances.map((attendance) => (
            <Fragment key={attendance.user.id}>
              <ListItem>
                <ListItemIcon>
                  {attendance.attendance !== "attendance" ? (
                    <Checkbox
                      onClick={(event) =>
                        handleCheckboxClick(event, attendance)
                      }
                    />
                  ) : null}
                </ListItemIcon>
                <ListItemText
                  primary={`${attendance.user.first_name} ${attendance.user.last_name}`}
                  secondary={switchIcons(attendance.attendance)}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={(event) => handleMenuClick(event, attendance)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider light variant="inset" />
            </Fragment>
          ))}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {attendanceTypes[activityType].map((type) => (
              <MenuItem
                onClick={() => handleMenuItemClick(type.value)}
                key={type.value}
              >
                <ListItemIcon>{switchIcons(type.value)}</ListItemIcon>
                {type.label}
              </MenuItem>
            ))}
          </Menu>
        </List>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={handleSaveClick}>
          GUARDAR
        </Button>
      </Grid>
    </Grid>
  );
};

AttendanceList.propTypes = {
  attendances: PropTypes.array.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  activityType: PropTypes.string.isRequired,
  extraTypes: PropTypes.bool,
};

export default AttendanceList;
