//React
import { useState, useEffect } from "react";

// Material
import {
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  useTheme,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

// Routing
import { Link, useHistory } from "react-router-dom";

// Firebase
import { emailLogin, googleLogin, logOut } from "assets/plugins/firebase/auth";

// Components
import Alert from "components/Alert";

// Log
import { logger } from "assets/plugins/log";

// Current user
import useUser from "assets/hooks/useUser";

const Login = () => {
  const logo = require("assets/images/logo.svg").default;

  // States
  const [EmailState, setEmailState] = useState("");
  const [PasswordState, setPasswordState] = useState("");
  const [BadLoginState, setBadLoginState] = useState(false);
  const [ErrorMessageState, setErrorMessageState] = useState({
    status: false,
    message: "",
  });
  const [LoadingState, setLoadingState] = useState(false);

  const history = useHistory();

  const theme = useTheme();
  const { currentUser, pending } = useUser();

  useEffect(() => {
    logger("[Login] page loaded.", { source: "/auth/login" });
  }, []);

  useEffect(() => {
    if (!currentUser) {
      logger("User not logged in.", { source: "/auth/login" });
    } else if (currentUser.claims.user_type === "catechist") {
      logger("Catechist already logged in.", {
        source: "/auth/login",
        payload: { currentUser },
      });
      history.replace("/admin/dashboard");
    } else if (currentUser.claims.user_type === "confirmand") {
      logger("Confirmand already logged in.", {
        source: "/auth/login",
        payload: { currentUser },
      });
      history.replace("/confirmand/dashboard");
    } else if (currentUser.claims.user_type === "guardian") {
      logger("Guardian already logged in.", {
        source: "/auth/login",
        payload: { currentUser },
      });
      history.replace("/guardian/confirmands");
    }
  }, [pending, LoadingState]);

  // Handlers
  const handleLoginClick = async (event) => {
    setLoadingState(true);
    event.preventDefault();

    try {
      await emailLogin(EmailState, PasswordState);
    } catch (error) {
      setBadLoginState(true);
      setErrorMessageState({
        status: true,
        message: `${error.message} Intente de nuevo.`,
      });
    } finally {
      setLoadingState(false);
    }
  };

  const handleGoogleLoginClick = async (event) => {
    setLoadingState(true);
    event.preventDefault();

    try {
      const { user } = await googleLogin();
      const response = await user.getIdTokenResult();
      if (Object.prototype.hasOwnProperty.call(response.claims, "level")) {
        if (response.claims.user_type === "catechist") {
          history.replace("/admin/dashboard");
        } else if (response.claims.user_type === "guardian") {
          history.replace("/guardian/confirmands");
        } else {
          history.replace("/confirmand/dashboard");
        }
      } else {
        await logOut();
        setLoadingState(false);
        setErrorMessageState({
          status: true,
          message: "No hay usuario asociado a este correo.",
        });
        setBadLoginState(true);
      }
    } catch (error) {
      setBadLoginState(true);
      setLoadingState(false);
      setErrorMessageState({
        status: true,
        message: `${error.message} Intente de nuevo.`,
      });
    }
  };

  const handleEmailChange = (event) => {
    setEmailState(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPasswordState(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleLoginClick(event);
    }
  };

  return (
    <Grid container spacing={2} padding="5%" style={{ height: "100%" }}>
      <Grid item xs={12} md={7}>
        <Paper
          elevation={0}
          style={{
            height: "100%",
            padding: "5%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: theme.palette.background.neutral,
          }}
        >
          <img
            src={logo}
            alt="Logo de la Confirmación de la Parroquia Santa Marta"
            width="50%"
          />
          <Typography variant="h1">CONFI SM</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={5}>
        <Paper
          elevation={0}
          style={{
            height: "100%",
            padding: "5%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.background.neutral,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">Inicio de sesión</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                value={EmailState}
                onChange={handleEmailChange}
                onKeyDown={handleKeyPress}
                autoComplete="off"
                type="email"
                placeholder="Correo electrónico"
                label="Correo electrónico"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                value={PasswordState}
                onChange={handlePasswordChange}
                onKeyDown={handleKeyPress}
                autoComplete="off"
                type="password"
                placeholder="Password"
                label="Contraseña"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} paddingTop="5%">
            <Grid item xs={12}>
              <Button
                name="ingresar"
                variant="contained"
                fullWidth
                onClick={(e) => handleLoginClick(e)}
                disabled={LoadingState}
              >
                INGRESAR
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Link to="/auth/resetpassword">Reestablecer contraseña</Link>
            </Grid>
            <Grid item xs={12} alignItems={"center"}>
              <Typography textAlign="center" variant="h5">
                ó
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="white"
                fullWidth
                onClick={(e) => handleGoogleLoginClick(e)}
                disabled={LoadingState}
                startIcon={<GoogleIcon color="primary" />}
              >
                INGRESA CON GOOGLE
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Alert
        open={BadLoginState}
        onClose={() => setBadLoginState(false)}
        message={ErrorMessageState.message}
        severity="error"
      />
    </Grid>
  );
};

export default Login;
