// Prop Types
import PropTypes from "prop-types";

// React
import { useState } from "react";

// Material
import {
  Grid,
  List,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Late from "@mui/icons-material/AssignmentLateOutlined";

const LateList = ({ attendances, onMenuClick, onMenuItemClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event, attendance) => {
    onMenuClick(attendance);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (type) => {
    onMenuItemClick(type);
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <List style={{ backgroundColor: "white" }}>
          {attendances
            .filter((attendance) => attendance.attendance === "absence")
            .map((attendance) => (
              <ListItem key={attendance.user.id}>
                <ListItemText
                  primary={`${attendance.user.first_name} ${attendance.user.last_name}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={(event) => handleMenuClick(event, attendance)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => handleMenuItemClick("late")}>
              <ListItemIcon>
                <Late color="warning" />
              </ListItemIcon>
              Tardanza
            </MenuItem>
          </Menu>
        </List>
      </Grid>
    </Grid>
  );
};

LateList.propTypes = {
  attendances: PropTypes.array.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
};

export default LateList;
