import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  getAnalytics,
  isSupported as analyticsSupported,
} from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getMessaging, isSupported } from "firebase/messaging";

// Logger
import { logger } from "assets/plugins/log";

const firebaseConfig = {
  production: {
    apiKey: "AIzaSyCDK2ZxL51OyqzgJu3k_tbg8vlMdo6eyVA",
    authDomain: "confirmacionsantamarta.firebaseapp.com",
    projectId: "confirmacionsantamarta",
    storageBucket: "confirmacionsantamarta.appspot.com",
    messagingSenderId: "671739848426",
    appId: "1:671739848426:web:e0f9c7ceb8154ccf1f9993",
    measurementId: "G-S0R1JLK8RD",
  },
  staging: {
    apiKey: "AIzaSyDxBLcacu1myki0ncAnljdDgs3UkjjIggY",
    authDomain: "stagingconfirmacionsantamarta.firebaseapp.com",
    projectId: "stagingconfirmacionsantamarta",
    storageBucket: "stagingconfirmacionsantamarta.appspot.com",
    messagingSenderId: "16277380453",
    appId: "1:16277380453:web:e387ac20c62f52bab6558c",
    measurementId: "G-Q1JNP896Z0",
  },
  development: {
    apiKey: "AIzaSyCDK2ZxL51OyqzgJu3k_tbg8vlMdo6eyVA",
    authDomain: "confirmacionsantamarta.firebaseapp.com",
    projectId: "confirmacionsantamarta",
    storageBucket: "confirmacionsantamarta.appspot.com",
    messagingSenderId: "671739848426",
    appId: "1:671739848426:web:e0f9c7ceb8154ccf1f9993",
    measurementId: "G-S0R1JLK8RD",
  },
  cypress: {
    apiKey: "AIzaSyCDK2ZxL51OyqzgJu3k_tbg8vlMdo6eyVA",
    authDomain: "confirmacionsantamarta.firebaseapp.com",
    projectId: "confirmacionsantamarta",
    storageBucket: "confirmacionsantamarta.appspot.com",
    messagingSenderId: "671739848426",
    appId: "1:671739848426:web:e0f9c7ceb8154ccf1f9993",
    measurementId: "G-S0R1JLK8RD",
  },
};

// Initialize Firebase
const app = initializeApp(firebaseConfig[process.env.REACT_APP_ENV]);

export const analytics = async () =>
  (await analyticsSupported()) ? getAnalytics(app) : null;
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const messaging = async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    console.error("Browser not supported");
    return null;
  } catch (e) {
    logger(error, {
      level: "error",
      source: "Firebase Index",
      payload: e,
    });
    return null;
  }
};

export default app;
