import { Box } from "@mui/material";
import { Route, Switch, Redirect } from "react-router-dom";

import { routes } from "components/Routes";

const AuthLayout = () => {
  const getRoutes = (routes) => {
    const children = routes.find((route) => route.path === "/auth").children;

    return children.map((route, key) => (
      <Route path={"/auth/" + route.path} component={route.element} key={key} />
    ));
  };

  return (
    <Box bgcolor="background.neutral" width="100vw" height="100vh">
      <Switch>
        {getRoutes(routes)}
        <Redirect from="*" to="/auth/login" />
      </Switch>
    </Box>
  );
};

export default AuthLayout;
