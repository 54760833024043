// Prop Types
import PropTypes from "prop-types";

// React
import { useState, useRef } from "react";

// Functions
import { getCroppedImage } from "assets/functions";

// Material
import { Grid, Avatar, Button, Dialog, DialogContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CloudDoneIcon from "@mui/icons-material/CloudDone";
import Edit from "@mui/icons-material/ModeEditOutlineOutlined";

// Firebase
import {
  updateUserProfileImageStorage,
  getUserProfileImageDownloadURL,
} from "assets/plugins/firebase/storage";

// Axios
import { updateDisplayPicture } from "assets/plugins/axios";

// React Crop
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// Components
import Alert from "components/Alert";

const DisplayEditAvatar = ({ userUid, imageUrl, disabled, token }) => {
  const handleImageChange = (event) => {
    const eventTargetFiles = event.target.files;
    if (eventTargetFiles && eventTargetFiles.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImageState(reader.result.toString() || ""),
      );
      reader.readAsDataURL(eventTargetFiles[0]);
      setImageCropDialogOpenState(true);
    }
  };

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  };

  const handleImageLoad = (event) => {
    const { width, height } = event.currentTarget;
    setCropState(centerAspectCrop(width, height, AspectState));
  };

  const handleImageSave = async () => {
    setSavingImageState(true);
    try {
      const croppedImage = await getCroppedImage(
        imageRef.current,
        CompletedCropState,
        1,
        0,
      );
      await updateUserProfileImageStorage(croppedImage, userUid);
      const imageURL = await getUserProfileImageDownloadURL(userUid);
      await updateDisplayPicture({
        token: token,
        data: {
          user: userUid,
          photoUrl: imageURL,
        },
      });
      setImageState(imageURL);
      setImageCropDialogOpenState(false);
      setSavingImageState(false);
    } catch (error) {
      setImageCropDialogOpenState(false);
      setSavingImageState(false);
      setErrorState({
        status: true,
        message: `${error} Intente de nuevo.`,
      });
    }
  };

  const handleImageCancel = () => {
    setImageState(imageUrl);
    setImageCropDialogOpenState(false);
  };

  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });
  const [SavingImageState, setSavingImageState] = useState(false);
  const [ImageState, setImageState] = useState(imageUrl);
  const [ImageCropDialogOpenState, setImageCropDialogOpenState] =
    useState(false);
  const [CropState, setCropState] = useState();
  const [CompletedCropState, setCompletedCropState] = useState();
  const [AspectState] = useState(1);
  const imageRef = useRef();

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Avatar
          src={ImageState}
          style={{
            width: "40vh",
            height: "40vh",
          }}
        />
      </Grid>
      {!disabled ? (
        <Grid item xs={12} textAlign="center">
          <label htmlFor="upload-photo">
            <input
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              accept="image/*"
            />
            <Button
              component="span"
              variant="contained"
              color="secondary"
              startIcon={<Edit />}
              style={{ margin: "1rem 1rem" }}
            >
              EDITAR
            </Button>
          </label>
        </Grid>
      ) : null}
      <Grid item xs={12} textAlign="center">
        <Dialog open={ImageCropDialogOpenState} fullWidth>
          <DialogContent>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <ReactCrop
                  crop={CropState}
                  onChange={(_, percentCrop) => setCropState(percentCrop)}
                  onComplete={setCompletedCropState}
                  aspect={AspectState}
                >
                  <img
                    src={ImageState}
                    alt="Crop me"
                    onLoad={handleImageLoad}
                    ref={imageRef}
                  />
                </ReactCrop>
              </Grid>
              <Grid item xs={6} textAlign="end">
                <LoadingButton
                  onClick={handleImageSave}
                  loading={SavingImageState}
                  variant="contained"
                  color="secondary"
                  startIcon={<CloudDoneIcon />}
                  style={{ margin: "1rem" }}
                >
                  GUARDAR
                </LoadingButton>
              </Grid>
              <Grid item xs={6} textAlign="start">
                <Button
                  onClick={handleImageCancel}
                  variant="outlined"
                  color="secondary"
                  style={{ margin: "1rem" }}
                >
                  CANCELAR
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

DisplayEditAvatar.propTypes = {
  userUid: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
};

export default DisplayEditAvatar;
