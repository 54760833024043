// React
import { useState, useEffect } from "react";

// Constants
import { groups } from "assets/constants";

// Current User
import useUser from "assets/hooks/useUser";

// Date fns
import { format, parse } from "date-fns";
import { es } from "date-fns/locale";

// Material
import { Paper, Grid, Typography } from "@mui/material";

// Routing
import { useParams } from "react-router-dom";

// Axios
import { activity, patchAttendance } from "assets/plugins/axios";

// Components
import AttendanceList from "components/AttendanceList";
import CollapseSection from "components/CollapseSection";
import AttendanceCommentModal from "components/AttendanceCommentModal";
import Loading from "components/Loading";
import Alert from "components/Alert";
import Error from "components/Error";

// Functions
import { getLabel } from "assets/functions";

// Lodash
import { chain } from "lodash";

// Log
import { logger } from "assets/plugins/log";

const AdminAttendance = () => {
  const { id } = useParams();

  const { currentUser } = useUser();

  const [LoadingState, setLoadingState] = useState(true);
  const [AttendanceState, setAttendanceState] = useState({});
  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });
  const [SelectedConfirmandState, setSelectedConfirmandState] = useState(null);
  const [AttendanceCommentDialogState, setAttendanceCommentDialogState] =
    useState({ open: false });

  const params = {
    expand:
      "attendances,attendances.user,attendances.user.confirmand,attendances.user.confirmand.confirmation_group",
    fields:
      "id,date,attendances,attendances.user.id,attendances.user.first_name,attendances.user.last_name,attendances.attendance,attendances.id,attendances.user.confirmand.id,activity_type,attendances.user.confirmand.confirmation_group.group",
  };

  if (!currentUser.claims.permissions.includes("view_all_level_attendance")) {
    params["group"] = currentUser.claims.group;
  }

  useEffect(() => {
    const getAttendance = async () => {
      try {
        const { data } = await activity({
          token: currentUser.token,
          params,
          id,
        });

        // Only confirmands
        const attendances = data.attendances.filter(
          (attendance) => attendance.user.confirmand !== null,
        );

        setAttendanceState({
          activity_type: data.activity_type,
          date: data.date,
          attendances: attendances,
        });
        setLoadingState(false);
      } catch (error) {
        setErrorState({
          status: true,
          message: error.message,
        });
      }
    };
    getAttendance();
    logger("[Admin Attendance] page loaded.", {
      payload: { currentUser },
      source: `/admin/attendance/${id}`,
    });
  }, []);

  const handleSaveClick = async (selectedConfirmands) => {
    setLoadingState(true);
    selectedConfirmands.forEach(async (confirmand) => {
      try {
        await patchAttendance({
          token: currentUser.token,
          id: confirmand.id,
          data: { attendance: "attendance" },
        });
      } catch (error) {
        setErrorState({
          status: true,
          message: error.message,
        });
      }
    });
    setAttendanceState((prevState) => {
      const newState = {
        ...prevState,
        attendances: prevState.attendances.map((attendance) => {
          if (
            selectedConfirmands.some(
              (confirmand) => confirmand.id === attendance.id,
            )
          ) {
            return {
              ...attendance,
              attendance: "attendance",
            };
          }
          return attendance;
        }),
      };
      return newState;
    });
    setLoadingState(false);
  };

  const handleMenuItemClick = (type) => {
    if (type === "excusedabsence") {
      setAttendanceCommentDialogState({
        open: true,
        type,
      });
    } else {
      handleAttendanceSave("", type);
    }
  };

  const handleAttendanceSave = async (comments, type) => {
    setLoadingState(true);
    try {
      await patchAttendance({
        token: currentUser.token,
        id: SelectedConfirmandState.id,
        data: {
          attendance: type,
          comments,
        },
      });
      setAttendanceState((prevState) => {
        const newState = {
          ...prevState,
          attendances: prevState.attendances.map((attendance) => {
            if (attendance.id === SelectedConfirmandState.id) {
              return {
                ...attendance,
                attendance: type,
              };
            }
            return attendance;
          }),
        };

        return newState;
      });
      setAttendanceCommentDialogState({ open: false });
      setLoadingState(false);
    } catch (error) {
      setErrorState({
        status: true,
        message: error.message,
      });
      setLoadingState(false);
    }
  };

  if (LoadingState) return <Loading />;
  if (ErrorState.status) return <Error errorMessage={ErrorState.message} />;

  if (Object.keys(AttendanceState).length === 0) {
    return <Typography variant="h1">No hay asistencia hoy</Typography>;
  }

  return (
    <Grid container spacing={5}>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: "1%" }}>
            <Typography variant="h1">
              {`Asistencia a ${AttendanceState.activity_type} del ${format(
                parse(
                  AttendanceState.date,
                  "yyyy-MM-dd'T'HH:mm:ssxxx",
                  new Date(),
                ),
                "dd 'de' MMMM",
                { locale: es },
              )}`}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {chain(AttendanceState.attendances)
            .groupBy("user.confirmand.confirmation_group.group")
            .map((attendances, group) => {
              return {
                group: group,
                attendances: attendances,
              };
            })
            .value()
            .filter(({ group }) => {
              const currentGroup = parseInt(group);
              const userGroup = parseInt(
                getLabel(
                  groups[currentUser.claims.level],
                  currentUser.claims.group,
                ),
              );
              return (
                currentUser.claims.permissions.includes(
                  "view_all_level_attendance",
                ) || currentGroup === userGroup
              );
            })
            .map(({ group, attendances }) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={group}
                  component={Paper}
                  margin={"1% 0"}
                  padding={"1%"}
                  elevation={0}
                >
                  <CollapseSection title={`Grupo ${group}`}>
                    <AttendanceList
                      onMenuClick={setSelectedConfirmandState}
                      onMenuItemClick={(type) => handleMenuItemClick(type)}
                      attendances={attendances}
                      onSave={(selectedItems) => handleSaveClick(selectedItems)}
                      activityType={AttendanceState.activity_type}
                      extraTypes={currentUser.claims.permissions.includes(
                        "change_advanced_attendance",
                      )}
                    />
                  </CollapseSection>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <AttendanceCommentModal
        open={AttendanceCommentDialogState.open}
        onSave={(comments) =>
          handleAttendanceSave(comments, AttendanceCommentDialogState.type)
        }
        onCancel={() => setAttendanceCommentDialogState({ open: false })}
      />
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

export default AdminAttendance;
