// Prop types
import PropTypes from "prop-types";

// React
import { useState } from "react";

// Material
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Loading
import Loading from "components/Loading";

const CollapseSection = ({
  children,
  title,
  loading = false,
  onClick,
  defaultExpanded = true,
}) => {
  const [Expanded, setExpanded] = useState(defaultExpanded);

  const childrenOrLoading = loading ? <Loading /> : children;

  const onTitleClick = () => {
    setExpanded(!Expanded);
    if (onClick && !Expanded) {
      onClick();
    }
  };

  return (
    <Accordion
      expanded={Expanded}
      onChange={onTitleClick}
      disableGutters={true}
      square={true}
      defaultExpanded={defaultExpanded}
      elevation={0}
      style={{ width: "100%" }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h2">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{childrenOrLoading}</AccordionDetails>
    </Accordion>
  );
};

CollapseSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  defaultExpanded: PropTypes.bool,
};

export default CollapseSection;
