// React
import { useState, useEffect } from "react";

// Constants
import { sexs, tshirtSizes, teams, groups } from "assets/constants";

// Axios
import {
  createUser,
  deleteUser,
  createCatechist,
  permissionGroups,
} from "assets/plugins/axios";

// Date-fns
import { format } from "date-fns";

// User
import useUser from "assets/hooks/useUser";

// Routing
import { useHistory } from "react-router-dom";

// Material
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

// Components
import Alert from "components/Alert";
import Error from "components/Error";
import Loading from "components/Loading";
import ConfirmDialog from "components/ConfirmDialog";

// Functions
import { getValue } from "assets/functions";

// Log
import { logger } from "assets/plugins/log";

const AddCatechists = () => {
  const history = useHistory();

  const { currentUser } = useUser();

  const [LoadingState, setLoadingState] = useState(true);
  const [LoadErrorState, setLoadErrorState] = useState({
    status: false,
    message: "",
  });
  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });
  const [ConfirmDialogState, setConfirmDialogState] = useState({
    open: false,
    loading: false,
    title: "",
    description: "",
    confirmText: "",
    cancelText: "",
    onConfirm: () => {},
    confirmColor: "primary",
  });
  const [BirthdateState, setBirthdateState] = useState(new Date());
  const [GroupPermissionsState, setGroupPermissionsState] = useState([]);
  const [GroupPermissionOptionsState, setGroupPermissionOptionsState] =
    useState([]);

  useEffect(() => {
    logger("[Add Catechists] page loaded.", {
      payload: { currentUser },
      source: "/admin/catechists/add",
    });
  }, []);

  useEffect(() => {
    const getPermissionGroups = async () => {
      try {
        const { data } = await permissionGroups({ token: currentUser.token });

        setGroupPermissionOptionsState(
          data.results.filter((group) => group.level_group.is_catechist_group),
        );
      } catch (error) {
        setLoadErrorState({
          status: true,
          message: error.message,
        });
      } finally {
        setLoadingState(false);
      }
    };

    getPermissionGroups();
  }, []);

  const handleCancelClick = () => {
    setConfirmDialogState({
      open: true,
      loading: false,
      title: "Cancelar creación de catequista",
      description:
        "Si aceptas, borrarás al usuario y la información escrita en este formulario",
      confirmText: "ACEPTAR",
      cancelText: "CANCELAR",
      onConfirm: handleDialogCancel,
      confirmColor: "error",
    });
  };

  const handleSaveClick = (event) => {
    event.preventDefault();

    const formData = {
      first_name: event.target.first_name.value,
      second_name: event.target.second_name.value,
      last_name: event.target.last_name.value,
      second_last_name: event.target.second_last_name.value,
      email: event.target.email.value,
      cellphone: event.target.cellphone.value,
      birthdate: event.target.birthdate.value,
      tshirt_size: event.target.tshirt_size.value,
      sex: event.target.sex.value,
      team: event.target.team.value,
    };

    setConfirmDialogState({
      open: true,
      loading: false,
      title: "Confirmar creación de catequista",
      description:
        "Si aceptas, agregarás al usuario y la información escrita en este formulario",
      confirmText: "AGREGAR",
      cancelText: "CANCELAR",
      onConfirm: () => handleDialogConfirm(formData),
    });
  };

  const handleDialogClose = () => {
    setConfirmDialogState({
      open: false,
      loading: false,
      title: "",
      description: "",
      confirmText: "",
      cancelText: "",
      onConfirm: () => {},
    });
  };

  const handleDialogCancel = () => {
    handleDialogClose();
    history.push("/admin/catechists");
  };

  const handleDialogConfirm = async (formData) => {
    setConfirmDialogState({
      ...ConfirmDialogState,
      loading: true,
    });

    try {
      const userResponse = await createUser({
        token: currentUser.token,
        data: {
          uid: "NOT REGISTERED IN FIREBASE",
          first_name: formData.first_name,
          second_name: formData.second_name,
          last_name: formData.last_name,
          second_last_name: formData.second_last_name,
          email: formData.email,
          cellphone: formData.cellphone,
          sex: getValue(sexs, formData.sex),
        },
      });

      try {
        await createCatechist({
          token: currentUser.token,
          data: {
            user: userResponse.data.id,
            birthdate: format(BirthdateState, "yyyy-MM-dd"),
            tshirt_size: getValue(tshirtSizes, formData.tshirt_size),
            confirmation_group: groups[currentUser.claims.level][0].value,
            team: getValue(teams, formData.team),
            level: currentUser.claims.level,
            groups: GroupPermissionsState.map(
              (groupPermission) => groupPermission.id,
            ),
          },
        });
      } catch (error) {
        await deleteUser({
          token: currentUser.token,
          id: userResponse.data.id,
        });
        error.response.data.message = `[Catequista] ${error.response.data.message}`;
        throw error;
      }
      history.push("/admin/catechists");
    } catch (error) {
      let errorMessage = error.response.data.message;
      logger(error.response.data.message, {
        level: "error",
        source: "/admin/catechists/add",
        payload: currentUser,
      });
      if (typeof error.response.data.message === "object") {
        errorMessage = JSON.stringify(error.response.data.message);
      }
      setErrorState({
        status: true,
        message: `${errorMessage} Intente de nuevo.`,
      });
      handleDialogClose();
    }
  };

  if (LoadingState) return <Loading />;
  if (LoadErrorState.status)
    return <Error errorMessage={LoadErrorState.message} />;

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSaveClick}>
      {/* Catequista  */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" paddingBottom="2%">
            Catequista
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
              <TextField fullWidth label="Nombre" required name="first_name" />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
              <TextField fullWidth label="Segundo Nombre" name="second_name" />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
              <TextField fullWidth label="Apellido" required name="last_name" />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
              <TextField
                fullWidth
                label="Segundo Apellido"
                name="second_last_name"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
              <TextField
                fullWidth
                label="Correo electrónico"
                required
                name="email"
                type="email"
              />
            </Grid>
            <Grid item xs={12} sm={5} md={3} lg={2} xl={2}>
              <TextField fullWidth label="Celular" name="cellphone" />
            </Grid>
            <Grid item xs={12} sm={5} md={3} lg={2} xl={2}>
              <DatePicker
                label="Fecha de nacimiento"
                value={BirthdateState}
                onChange={setBirthdateState}
                renderInput={(params) => (
                  <TextField {...params} required fullWidth name="birthdate" />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={2} md={3} lg={2} xl={2}>
              <Autocomplete
                options={tshirtSizes}
                autoSelect
                autoHighlight
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Talla"
                    required
                    name="tshirt_size"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={2} md={3} lg={1} xl={2}>
              <Autocomplete
                options={sexs}
                autoSelect
                autoHighlight
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Sexo" required name="sex" />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
              <Autocomplete
                options={teams}
                autoSelect
                autoHighlight
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Comisión"
                    required
                    name="team"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
              <Autocomplete
                multiple
                options={GroupPermissionOptionsState}
                getOptionLabel={(option) => option.level_group.name}
                onChange={(_, value) => setGroupPermissionsState(value)}
                autoHighlight
                fullWidth
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Grupo de permisos"
                    name="permission_groups"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} textAlign="end" style={{ marginBottom: "50vh" }}>
        <Button
          variant="outlined"
          color="error"
          style={{ margin: "1rem" }}
          onClick={handleCancelClick}
        >
          CANCELAR
        </Button>
        <Button variant="contained" style={{ margin: "1rem" }} type="submit">
          CREAR
        </Button>
      </Grid>
      <ConfirmDialog
        open={ConfirmDialogState.open}
        loading={ConfirmDialogState.loading}
        title={ConfirmDialogState.title}
        description={ConfirmDialogState.description}
        onConfirm={ConfirmDialogState.onConfirm}
        onClose={() =>
          setConfirmDialogState({
            open: false,
            loading: false,
          })
        }
        confirmText={ConfirmDialogState.confirmText}
        cancelText={ConfirmDialogState.cancelText}
        confirmColor={ConfirmDialogState.confirmColor}
      />
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

export default AddCatechists;
