// Prop Types
import PropTypes from "prop-types";

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Zoom,
} from "@mui/material";

const AttendanceCommentModal = ({ open, onSave, onCancel }) => {
  const handleClose = async (event) => {
    event.preventDefault();
    const comments = event.target.comments.value;
    onSave(comments);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Zoom}
      fullWidth
      maxWidth={"xl"}
      component="form"
      onSubmit={handleClose}
    >
      <DialogTitle>Comentarios</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Menciona la razón por la cual el confirmando no asistió a la
          confirmación.
        </DialogContentText>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              type="text"
              fullWidth
              label="Comentarios"
              required
              name="comments"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>CANCELAR</Button>
        <Button type="submit">GUARDAR</Button>
      </DialogActions>
    </Dialog>
  );
};

AttendanceCommentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AttendanceCommentModal;
