// React
import { useState, useEffect } from "react";

// Constants
import { activityTypes, attendanceTypes } from "assets/constants";

// Current User
import useUser from "assets/hooks/useUser";

// Date fns
import { parse, format } from "date-fns";
import { es } from "date-fns/locale";

// Material
import { Grid, Paper, Typography } from "@mui/material";

// Routing
import { useParams, useHistory } from "react-router-dom";

// Theming
import theme from "theme";

// Axios
import {
  mass,
  catechesis,
  meeting,
  patchActivity,
  patchCatechesis,
} from "assets/plugins/axios";

// Components
import Loading from "components/Loading";
import DisplayEditField from "components/DisplayEditField";
import DisplayCheckField from "components/DisplayCheckField";
import DisplayAutoComplete from "components/DisplayAutoComplete";
import DisplayDateTimePicker from "components/DisplayDateTimePicker";
import CollapseSection from "components/CollapseSection";
import Alert from "components/Alert";
import Error from "components/Error";

// Functions
import { getLabel, getValue } from "assets/functions";

// Chart
import { PieChart, Pie, ResponsiveContainer, Legend, Cell } from "recharts";

// Log
import { logger } from "assets/plugins/log";

const AdminActivity = () => {
  const { id } = useParams();

  const { currentUser } = useUser();

  const history = useHistory();

  const activityType = history.location.state.type;

  const editPermission =
    currentUser.claims.permissions.includes("change_activity");
  const generalStatsPermission = currentUser.claims.permissions.includes(
    "view_all_level_attendance",
  );
  const is_meeting = activityType === "Reunión";

  const activityQuery = {
    Misa: mass,
    Catequesis: catechesis,
    Reunión: meeting,
  };

  const [LoadingState, setLoadingState] = useState(true);
  const [ActivityState, setActivityState] = useState({});
  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        const { data } = await activityQuery[activityType]({
          token: currentUser.token,
          params: { id },
        });
        const activity_instance = data.results[0];

        let attendance = {};
        let confirmandsGroupAttendance = {};
        let confirmandsAttendance = {};
        let groupAttendance = {};

        if (!is_meeting) {
          attendance = Object.entries(activity_instance.attendance)
            .map(([attendance, count]) => ({
              name: getLabel(attendanceTypes, attendance),
              count,
            }))
            .sort((a, b) => (b.name > a.name ? -1 : 1));

          confirmandsGroupAttendance = Object.entries(
            activity_instance.confirmands_attendance_by_group[
              currentUser.claims.group
            ],
          )
            .map(([attendance, count]) => ({
              name: getLabel(attendanceTypes, attendance),
              count,
            }))
            .sort((a, b) => (b.name > a.name ? -1 : 1));

          confirmandsAttendance = Object.entries(
            activity_instance.confirmands_attendance,
          )
            .map(([attendance, count]) => ({
              name: getLabel(attendanceTypes, attendance),
              count,
            }))
            .sort((a, b) => (b.name > a.name ? -1 : 1));

          groupAttendance = Object.entries(
            activity_instance.attendance_by_group[currentUser.claims.group],
          )
            .map(([attendance, count]) => ({
              name: getLabel(attendanceTypes, attendance),
              count,
            }))
            .sort((a, b) => (b.name > a.name ? -1 : 1));
        }

        const catechistsGroupAttendance = Object.entries(
          activity_instance.catechists_attendance_by_group[
            currentUser.claims.group
          ],
        )
          .map(([attendance, count]) => ({
            name: getLabel(attendanceTypes, attendance),
            count,
          }))
          .sort((a, b) => (b.name > a.name ? -1 : 1));

        const catechistsAttendance = Object.entries(
          activity_instance.catechists_attendance,
        )
          .map(([attendance, count]) => ({
            name: getLabel(attendanceTypes, attendance),
            count,
          }))
          .sort((a, b) => (b.name > a.name ? -1 : 1));

        setActivityState({
          id: activity_instance.id,
          activity_id: activity_instance.activity.id,
          date: parse(
            activity_instance.activity.date,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date(),
          ),
          type: activity_instance.activity.activity_type,
          comments: activity_instance.activity.comments,
          has_attendance: activity_instance.activity.has_attendance,
          topic: activityType === "Catequesis" ? activity_instance.topic : "",
          confirmandsGroupAttendance,
          catechistsGroupAttendance,
          groupAttendance,
          confirmandsAttendance,
          catechistsAttendance,
          attendance,
        });
        setLoadingState(false);
      } catch (error) {
        console.error(error);
        setErrorState({
          status: true,
          message: error.message,
        });
        setLoadingState(false);
      }
    };
    fetchActivity();
    logger("[Admin Activity] page loaded.", {
      payload: { currentUser },
      source: `/admin/activity/${id}`,
    });
  }, [id, currentUser.token, currentUser.claims.group]);

  const onEditSave = (data) => {
    setActivityState({
      ...ActivityState,
      ...data,
    });
  };

  if (LoadingState) return <Loading />;
  if (ErrorState.status) return <Error errorMessage={ErrorState.message} />;

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h1">{`${activityType} del ${format(
            ActivityState.date,
            "dd 'de' MMMM",
            { locale: es },
          )}`}</Typography>
        </Paper>
      </Grid>
      {/* Asistencia grupal */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Asistencia grupal">
            <Grid container spacing={3}>
              {!is_meeting ? (
                <>
                  <Grid item xs={12} md={6}>
                    <Paper elevation={0} style={{ padding: "3%" }}>
                      <Typography variant="h3">General</Typography>
                      <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                          <Legend verticalAlign="bottom" />
                          <Pie
                            data={ActivityState.groupAttendance}
                            legendType="line"
                            innerRadius={50}
                            dataKey="count"
                            nameKey="name"
                            label
                          >
                            <Cell fill={theme.palette.success.dark} />
                            <Cell fill={theme.palette.error.dark} />
                            <Cell fill={theme.palette.info.dark} />
                            <Cell fill={theme.palette.warning.dark} />
                            <Cell fill={theme.palette.warning.main} />
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper elevation={0} style={{ padding: "3%" }}>
                      <Typography variant="h3">Confirmandos</Typography>
                      <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                          <Legend verticalAlign="bottom" />
                          <Pie
                            data={ActivityState.confirmandsGroupAttendance}
                            legendType="line"
                            innerRadius={50}
                            dataKey="count"
                            nameKey="name"
                            label
                          >
                            <Cell fill={theme.palette.success.dark} />
                            <Cell fill={theme.palette.error.dark} />
                            <Cell fill={theme.palette.info.dark} />
                            <Cell fill={theme.palette.warning.dark} />
                            <Cell fill={theme.palette.warning.main} />
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Paper>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <Paper elevation={0} style={{ padding: "3%" }}>
                  <Typography variant="h3">Catequistas</Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Legend verticalAlign="bottom" />
                      <Pie
                        data={ActivityState.catechistsGroupAttendance}
                        legendType="line"
                        innerRadius={50}
                        dataKey="count"
                        nameKey="name"
                        label
                      >
                        <Cell fill={theme.palette.success.dark} />
                        <Cell fill={theme.palette.error.dark} />
                        <Cell fill={theme.palette.info.dark} />
                        <Cell fill={theme.palette.warning.dark} />
                        <Cell fill={theme.palette.warning.main} />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Actividad  */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Actividad">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchActivity({
                      token: currentUser.token,
                      id: ActivityState.activity_id,
                      data: { foo: value },
                    })
                  }
                  options={activityTypes}
                  label="Tipo de actividad"
                  defaultValue={getValue(activityTypes, ActivityState.type)}
                  name="type"
                  disabled
                  onSave={onEditSave}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <DisplayDateTimePicker
                  patch={(value) =>
                    patchActivity({
                      token: currentUser.token,
                      id: ActivityState.activity_id,
                      data: { date: value },
                    })
                  }
                  label="Fecha"
                  loading={LoadingState}
                  defaultValue={ActivityState.date}
                  name="date"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <DisplayEditField
                  patch={(value) =>
                    patchActivity({
                      token: currentUser.token,
                      id: ActivityState.activity_id,
                      data: { comments: value },
                    })
                  }
                  type="text"
                  label="Comentarios"
                  defaultValue={ActivityState.comments}
                  name="comments"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <DisplayCheckField
                  patch={(value) =>
                    patchActivity({
                      token: currentUser.token,
                      id: ActivityState.activity_id,
                      data: { has_attendance: value },
                    })
                  }
                  defaultValue={ActivityState.has_attendance}
                  label="¿Hay asistencia?"
                  name="has_attendance"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              {activityType === "Catequesis" ? (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <DisplayEditField
                    patch={(value) =>
                      patchCatechesis({
                        token: currentUser.token,
                        id: ActivityState.id,
                        data: { topic: value },
                      })
                    }
                    defaultValue={ActivityState.topic}
                    label="Tema"
                    name="topic"
                    onSave={onEditSave}
                    disabled={!editPermission}
                  />
                </Grid>
              ) : null}
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Asistencia general */}
      {generalStatsPermission ? (
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{
              padding: "3%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CollapseSection title="Asistencia general">
              <Grid container spacing={3}>
                {!is_meeting ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <Paper elevation={0} style={{ padding: "3%" }}>
                        <Typography variant="h3">General</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <PieChart>
                            <Legend verticalAlign="bottom" />
                            <Pie
                              data={ActivityState.attendance}
                              legendType="line"
                              innerRadius={50}
                              dataKey="count"
                              nameKey="name"
                              label
                            >
                              <Cell fill={theme.palette.success.dark} />
                              <Cell fill={theme.palette.error.dark} />
                              <Cell fill={theme.palette.info.dark} />
                              <Cell fill={theme.palette.warning.dark} />
                              <Cell fill={theme.palette.warning.main} />
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Paper elevation={0} style={{ padding: "3%" }}>
                        <Typography variant="h3">Confirmandos</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <PieChart>
                            <Legend verticalAlign="bottom" />
                            <Pie
                              data={ActivityState.confirmandsAttendance}
                              legendType="line"
                              innerRadius={50}
                              dataKey="count"
                              nameKey="name"
                              label
                            >
                              <Cell fill={theme.palette.success.dark} />
                              <Cell fill={theme.palette.error.dark} />
                              <Cell fill={theme.palette.info.dark} />
                              <Cell fill={theme.palette.warning.dark} />
                              <Cell fill={theme.palette.warning.main} />
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      </Paper>
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12}>
                  <Paper elevation={0} style={{ padding: "3%" }}>
                    <Typography variant="h3">Catequistas</Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Legend verticalAlign="bottom" />
                        <Pie
                          data={ActivityState.catechistsAttendance}
                          legendType="line"
                          innerRadius={50}
                          dataKey="count"
                          nameKey="name"
                          label
                        >
                          <Cell fill={theme.palette.success.dark} />
                          <Cell fill={theme.palette.error.dark} />
                          <Cell fill={theme.palette.info.dark} />
                          <Cell fill={theme.palette.warning.dark} />
                          <Cell fill={theme.palette.warning.main} />
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Paper>
                </Grid>
              </Grid>
            </CollapseSection>
          </Paper>
        </Grid>
      ) : null}
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

export default AdminActivity;
