// Prop Types
import PropTypes from "prop-types";

// Material
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";

// Routing
import { useHistory } from "react-router-dom";

import { routes } from "components/Routes";

const GuardianDrawer = ({ open, onClose, logo }) => {
  const history = useHistory();

  const getLinks = (routes) => {
    const children = routes.find(
      (route) => route.path === "/guardian",
    ).children;

    const drawerChildren = children.filter((route) => route.drawer);

    const handleItemClick = (route) => {
      history.push("/guardian/" + route.path);
      onClose();
    };

    return drawerChildren.map((route, key) => {
      return (
        <ListItem onClick={() => handleItemClick(route)} button key={key}>
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText>{route.name}</ListItemText>
        </ListItem>
      );
    });
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <List style={{ padding: "1rem" }}>
        <img
          src={logo}
          alt="Logo de la Confirmacion Santa Marta"
          width="200rem"
        />
        <Divider />
        {getLinks(routes)}
      </List>
    </Drawer>
  );
};

GuardianDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  logo: PropTypes.string.isRequired,
};

export default GuardianDrawer;
