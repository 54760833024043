import { messaging } from "./index";
import { getToken, onMessage } from "firebase/messaging";
import { logger } from "assets/plugins/log";

const vapidKey = {
  development:
    "BIouWJMr90POpZ1ZWfogPCFC_HnavD3fXaFPkCPkjwbjVjUWZfvTVyy5NHy6VCX4mNEj7ZLntvzyr0YMwtRZWgo",
  staging:
    "BOQiX3ywG7951kw8AyboWB1hMUl8R2ZA0pXd4CRIfFIGRWgaIaA6WtqRrrsD4JxVbkIqPJ5Oo81LEJ4NrxyOnX4",
  production:
    "BIouWJMr90POpZ1ZWfogPCFC_HnavD3fXaFPkCPkjwbjVjUWZfvTVyy5NHy6VCX4mNEj7ZLntvzyr0YMwtRZWgo",
};

export const getNotificationToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: vapidKey[process.env.REACT_APP_ENV],
    });
    if (currentToken) {
      return currentToken;
    } else {
      return null;
    }
  } catch (error) {
    logger("Error retrieving token.", {
      source: "firebase-messaging-sw.js",
      level: "error",
      payload: { error },
    });
  }
};

onMessage(messaging, (payload) => {
  // Send notification
  const notificationTitle = payload.data.title;
  const notificationOptions = { body: payload.data.body };

  new Notification(notificationTitle, notificationOptions);
});
