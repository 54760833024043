// Prop Types
import PropTypes from "prop-types";

// React
import { useState } from "react";

// Material
import { Grid, Typography, TextField, IconButton, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Edit from "@mui/icons-material/ModeEditOutlineOutlined";

// Components
import Alert from "components/Alert";

const DisplayEditField = ({
  type,
  label,
  defaultValue,
  multiline,
  name,
  onSave,
  disabled,
  patch,
}) => {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleCancelClick = () => {
    setEditing(false);
  };

  const dbUpdate = async (value) => {
    try {
      await patch(value);
      onSave({ [name]: value });
    } catch (error) {
      setErrorState({
        status: true,
        message: `${error.response.data.message} Intente de nuevo.`,
      });
    } finally {
      setEditing(false);
      setLoading(false);
    }
  };

  const handleSaveClick = async (event) => {
    event.preventDefault();
    setLoading(true);
    dbUpdate(event.target.textField.value);
  };

  if (editing) {
    return (
      <Grid
        container
        spacing={1}
        display="flex"
        alignItems="center"
        component="form"
        onSubmit={handleSaveClick}
      >
        <Grid item xs={12}>
          <TextField
            type={type}
            label={label}
            defaultValue={defaultValue}
            fullWidth
            multiline={multiline}
            minRows={4}
            name="textField"
          />
        </Grid>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12} textAlign="end">
            <Button
              disabled={loading}
              onClick={handleCancelClick}
              variant="outlined"
              color="error"
            >
              CANCELAR
            </Button>
          </Grid>
          <Grid item xs={12} textAlign="end">
            <LoadingButton loading={loading} type="submit" variant="contained">
              GUARDAR
            </LoadingButton>
          </Grid>
        </Grid>
        <Alert
          open={ErrorState.status}
          severity="error"
          message={ErrorState.message}
          onClose={() =>
            setErrorState({
              status: false,
              message: "",
            })
          }
        />
      </Grid>
    );
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} display="flex" alignItems="center">
        <Typography variant="subtitle1">{label}</Typography>
        {!disabled ? (
          <IconButton
            size="small"
            onClick={handleEditClick}
            style={{ margin: "0 1rem" }}
          >
            <Edit />
          </IconButton>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{defaultValue}</Typography>
      </Grid>
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

DisplayEditField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
  patch: PropTypes.func,
};

export default DisplayEditField;
