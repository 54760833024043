// Prop Types
import PropTypes from "prop-types";

// Material
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";

// Routing
import { useHistory } from "react-router-dom";

const GuardiansTable = ({
  rows,
  initialGridConfigState,
  onColumnVisibilityModelChange,
}) => {
  const history = useHistory();

  const pageSize = Math.floor(window.innerHeight * 0.014);

  const toggleSexIcons = (row) =>
    row.value === "F" ? <FemaleIcon /> : <MaleIcon />;

  const columns = [
    {
      field: "id",
      hideable: false,
    },
    {
      field: "user.first_name",
      headerName: "Nombre",
      type: "string",
      hideable: false,
    },
    {
      field: "user.second_name",
      headerName: "Segundo nombre",
      type: "string",
    },
    {
      field: "user.last_name",
      headerName: "Apellido",
      type: "string",
    },
    {
      field: "user.sex",
      headerName: "Sexo",
      description: "Sexo",
      type: "string",
      renderCell: toggleSexIcons,
    },
  ];

  return (
    <DataGrid
      onCellClick={(evt) => history.push(`/admin/guardian/${evt.row.id}`)}
      disableRowSelectionOnClick
      disableColumnMenu
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      initialState={initialGridConfigState}
      slots={{ toolbar: GridToolbar }}
      slotProps={{ toolbar: { showQuickFilter: true } }}
      style={{
        backgroundColor: "white",
        height: "90vh",
      }}
    />
  );
};

GuardiansTable.propTypes = {
  rows: PropTypes.array.isRequired,
  initialGridConfigState: PropTypes.any,
  onColumnVisibilityModelChange: PropTypes.func,
};

export default GuardiansTable;
