// React
import { useState, useEffect } from "react";

// Constants
import {
  sexs,
  schools,
  tshirtSizes,
  relationships,
  groups,
  attendanceTypes,
} from "assets/constants";

// Functions
import { getBiologicalParents, getLabel, switchIcons } from "assets/functions";

// Date fns
import { parse, format } from "date-fns";
import { es } from "date-fns/locale";

// Current User
import useUser from "assets/hooks/useUser";

// Material
import {
  Grid,
  Paper,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  ListItemIcon,
  Typography,
} from "@mui/material";

// Chart
import { PieChart, Pie, ResponsiveContainer, Legend, Cell } from "recharts";

// Routing
import { useParams, useHistory } from "react-router-dom";

// Theming
import theme from "theme";

import {
  getUserBaptismCertificateDownloadURL,
  updateUserBaptismCertificateStorage,
} from "assets/plugins/firebase/storage";

// Axios
import {
  confirmand,
  attendances,
  patchUser,
  patchConfirmand,
  patchRelationship,
  deleteRelationship,
  deleteUser,
} from "assets/plugins/axios";

// Components
import Loading from "components/Loading";
import DisplayEditField from "components/DisplayEditField";
import DisplayCheckField from "components/DisplayCheckField";
import DisplayAutoComplete from "components/DisplayAutoComplete";
import DisplayDatePicker from "components/DisplayDatePicker";
import DisplayEditAvatar from "components/DisplayEditAvatar";
import DisplayEditImage from "components/DisplayEditImage";
import CollapseSection from "components/CollapseSection";
import NewGuardianModal from "components/NewGuardianModal";
import Alert from "components/Alert";
import Error from "components/Error";
import ConfirmDialog from "components/ConfirmDialog";

// Log
import { logger } from "assets/plugins/log";

const AdminConfirmand = () => {
  const { id } = useParams();
  const history = useHistory();

  const { currentUser } = useUser();

  const [ConfirmandState, setConfirmandState] = useState({});
  const [InitialLoadingState, setInitialLoadingState] = useState(true);
  const [LoadErrorState, setLoadErrorState] = useState({
    status: false,
    message: "",
  });
  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });
  const [NewGuardianState, setNewGuardianState] = useState(false);
  const [ConfirmDialogState, setConfirmDialogState] = useState({
    open: false,
    loading: false,
    title: "",
    description: "",
    confirmText: "",
    cancelText: "",
    onConfirm: () => {},
  });
  const [AttendanceLoadingState, setAttendanceLoadingState] = useState(false);
  const [AttendanceState, setAttendanceState] = useState([]);

  const fieldsMap = {
    id: {
      fieldName: "id",
      expand: "",
    },
    address: {
      fieldName: "address",
      expand: "",
    },
    birthdate: {
      fieldName: "birthdate",
      expand: "",
    },
    school: {
      fieldName: "school",
      expand: "",
    },
    tshirt_size: {
      fieldName: "tshirt_size",
      expand: "",
    },
    is_fee_paid: {
      fieldName: "is_fee_paid",
      expand: "",
    },
    legal_id: {
      fieldName: "legal_id",
      expand: "",
    },
    allergies: {
      fieldName: "allergies",
      expand: "",
    },
    conditions: {
      fieldName: "conditions",
      expand: "",
    },
    medications: {
      fieldName: "medications",
      expand: "",
    },
    comments: {
      fieldName: "comments",
      expand: "",
    },
    confirmation_godparent_first_name: {
      fieldName: "confirmation_godparent_first_name",
      expand: "",
    },
    confirmation_godparent_second_name: {
      fieldName: "confirmation_godparent_second_name",
      expand: "",
    },
    confirmation_godparent_last_name: {
      fieldName: "confirmation_godparent_last_name",
      expand: "",
    },
    confirmation_godparent_second_last_name: {
      fieldName: "confirmation_godparent_second_last_name",
      expand: "",
    },
    is_first_communion_done: {
      fieldName: "is_first_communion_done",
      expand: "",
    },
    baptism_certificate_link: {
      fieldName: "baptism_certificate_link",
      expand: "",
    },
    confirmation_group: {
      fieldName: "confirmation_group",
      expand: "",
    },
    "user.id": {
      fieldName: "user.id",
      expand: "user",
    },
    "user.uid": {
      fieldName: "user.uid",
      expand: "user",
    },
    "user.email": {
      fieldName: "user.email",
      expand: "user",
    },
    "user.first_name": {
      fieldName: "user.first_name",
      expand: "user",
    },
    "user.second_name": {
      fieldName: "user.second_name",
      expand: "user",
    },
    "user.last_name": {
      fieldName: "user.last_name",
      expand: "user",
    },
    "user.second_last_name": {
      fieldName: "user.second_last_name",
      expand: "user",
    },
    "user.cellphone": {
      fieldName: "user.cellphone",
      expand: "user",
    },
    "user.sex": {
      fieldName: "user.sex",
      expand: "user",
    },
    "relationships.id": {
      fieldName: "relationships.id",
      expand: "relationships",
    },
    "relationships.relationship": {
      fieldName: "relationships.relationship",
      expand: "relationships",
    },
    "relationships.guardian": {
      fieldName: "relationships.guardian",
      expand: "relationships",
    },
    mass_attendance: {
      fieldName: "mass_attendance",
      expand: "",
    },
    catechesis_attendance: {
      fieldName: "catechesis_attendance",
      expand: "",
    },
  };

  const initialFields = [];
  const initialExpand = [];

  for (const field of Object.values(fieldsMap)) {
    initialFields.push(field.fieldName);
    if (!initialExpand.includes(field.expand)) {
      initialExpand.push(field.expand);
    }
  }

  const params = {
    id,
    fields: initialFields.toString(),
    expand: initialExpand.toString(),
  };

  useEffect(() => {
    const getConfirmand = async () => {
      try {
        const { data } = await confirmand({
          token: currentUser.token,
          params,
        });

        const relationships = getBiologicalParents(data.relationships);
        const confirmandGuardiansId = data.relationships.map(
          (relationship) => relationship.guardian.id,
        );

        const catechesisAttendance = Object.entries(data.catechesis_attendance)
          .map(([key, value]) => {
            return {
              name: getLabel(attendanceTypes, key),
              value: value,
            };
          })
          .sort((a, b) => (b.name > a.name ? -1 : 1));

        const massAttendance = Object.entries(data.mass_attendance)
          .map(([key, value]) => {
            return {
              name: getLabel(attendanceTypes, key),
              value: value,
            };
          })
          .sort((a, b) => (b.name > a.name ? -1 : 1));

        let baptism_certificate_link = "";
        try {
          baptism_certificate_link = await getUserBaptismCertificateDownloadURL(
            data.user.uid,
          );
        } catch (error) {
          // setErrorState({status: true, message: `${error}. Intente de nuevo.`,});
        }
        setConfirmandState({
          ...relationships,
          confirmandGuardiansId: confirmandGuardiansId,
          user_uid: data.user.uid,
          user_id: data.user.id,
          id: data.id,
          first_name: data.user.first_name,
          second_name: data.user.second_name,
          last_name: data.user.last_name,
          second_last_name: data.user.second_last_name,
          email: data.user.email,
          cellphone: data.user.cellphone,
          address: data.address,
          birthdate: parse(data.birthdate, "yyyy-MM-dd", new Date()),
          school: data.school,
          tshirt_size: data.tshirt_size,
          sex: data.user.sex,
          confirmation_group: data.confirmation_group,
          is_fee_paid: data.is_fee_paid,
          legal_id: data.legal_id,
          baptism_certificate_link: baptism_certificate_link,
          is_first_communion_done: data.is_first_communion_done,
          confirmation_godparent_first_name:
            data.confirmation_godparent_first_name,
          confirmation_godparent_second_name:
            data.confirmation_godparent_second_name,
          confirmation_godparent_last_name:
            data.confirmation_godparent_last_name,
          confirmation_godparent_second_last_name:
            data.confirmation_godparent_second_last_name,
          allergies: data.allergies,
          conditions: data.conditions,
          medications: data.medications,
          comments: data.comments,
          catechesisAttendance,
          massAttendance,
          imageUrl: `https://api.dicebear.com/7.x/bottts/svg?seed=${data.user.uid}`,
        });
        setInitialLoadingState(false);
      } catch (error) {
        if (error.name === "FirebaseError") {
          // Do nothing
        } else {
          setLoadErrorState({
            status: true,
            message: error.message,
          });
        }
        setInitialLoadingState(false);
      }
    };

    getConfirmand();
    logger("[Admin Confirmand] page loaded.", {
      payload: { currentUser },
      source: `/admin/confirmand/${id}`,
    });
  }, [id, currentUser.token]);

  const onEditSave = (data) => {
    setConfirmandState({
      ...ConfirmandState,
      ...data,
    });
  };

  const onBiologicalParentEditSave = async ({ data, relationship }) => {
    setConfirmandState({
      ...ConfirmandState,
      biologicalParents: {
        ...ConfirmandState.biologicalParents,
        [relationship]: {
          ...ConfirmandState.biologicalParents[relationship],
          ...data,
        },
      },
    });
  };

  const onGuardianEditSave = async ({ data, guardian_id }) => {
    setConfirmandState({
      ...ConfirmandState,
      remainingRelationships: {
        ...ConfirmandState.remainingRelationships,
        [guardian_id]: {
          ...ConfirmandState.remainingRelationships[guardian_id],
          ...data,
        },
      },
    });
  };

  const onNewGuardianClose = ({ newGuardian, canceled }) => {
    if (canceled) {
      setNewGuardianState(false);
    } else {
      setConfirmandState({
        ...ConfirmandState,
        remainingRelationships: {
          ...ConfirmandState.remainingRelationships,
          [newGuardian.relationship_id]: { ...newGuardian },
        },
      });
      setNewGuardianState(false);
    }
  };

  const onAttendanceClick = async () => {
    try {
      setAttendanceLoadingState(true);
      const { data } = await attendances({
        token: currentUser.token,
        params: {
          search: ConfirmandState.user_id,
          fields:
            "id,attendance,comments,activity.date,activity.activity_type,activity.id",
          expand: "activity",
        },
      });
      const formattedAttendances = data.results
        .map((attendance) => {
          return {
            id: attendance.id,
            date: parse(
              attendance.activity.date,
              "yyyy-MM-dd'T'HH:mm:ssxxx",
              new Date(),
            ),
            activity_type: attendance.activity.activity_type,
            attendance: attendance.attendance,
            comments: attendance.comments,
          };
        })
        .sort((a, b) => (b.date > a.date ? -1 : 1));
      setAttendanceState(formattedAttendances);
    } catch (error) {
      setErrorState({
        open: true,
        message: `${error}. Intente de nuevo.`,
      });
    } finally {
      setAttendanceLoadingState(false);
    }
  };

  const handleDeleteRelationship = ({ relationship_id, guardian }) => {
    const confirmandName =
      `${ConfirmandState.first_name} ${ConfirmandState.second_name} ${ConfirmandState.second_last_name} ${ConfirmandState.second_last_name}`
        .trim()
        .replace("  ", " ");
    const guardianName =
      `${guardian.first_name} ${guardian.second_name} ${guardian.second_last_name} ${guardian.second_last_name}`
        .trim()
        .replace("  ", " ");
    setConfirmDialogState({
      open: true,
      loading: false,
      title: "Desvincular acudiente",
      description: `¿Está seguro que desea desvincular al acudiente ${guardianName} del confirmando ${confirmandName}?`,
      confirmText: "DESVINCULAR",
      cancelText: "CANCELAR",
      onConfirm: async () => {
        setConfirmDialogState({
          ...ConfirmDialogState,
          loading: true,
        });
        try {
          await deleteRelationship({
            token: currentUser.token,
            id: relationship_id,
          });
          const newRemainingRelationships = {
            ...ConfirmandState.remainingRelationships,
          };
          delete newRemainingRelationships[relationship_id];
          setConfirmandState({
            ...ConfirmandState,
            remainingRelationships: newRemainingRelationships,
          });
          setConfirmDialogState({
            ...ConfirmDialogState,
            open: false,
            loading: false,
          });
        } catch (error) {
          setConfirmDialogState({
            ...ConfirmDialogState,
            open: true,
            loading: false,
          });
          setErrorState({
            open: true,
            message: `${error}. Intente de nuevo.`,
          });
        }
      },
    });
  };

  const handleDeleteUser = () => {
    const confirmandName =
      `${ConfirmandState.first_name} ${ConfirmandState.second_name} ${ConfirmandState.second_last_name} ${ConfirmandState.second_last_name}`
        .trim()
        .replace("  ", " ");
    setConfirmDialogState({
      open: true,
      loading: false,
      title: "Eliminar confirmando",
      description: `¿Está seguro que desea eliminar al confirmando ${confirmandName}?`,
      confirmText: "ELIMINAR",
      cancelText: "CANCELAR",
      onConfirm: async () => {
        setConfirmDialogState({
          ...ConfirmDialogState,
          loading: true,
        });
        try {
          await deleteUser({
            token: currentUser.token,
            id: ConfirmandState.user_id,
          });
          setConfirmDialogState({
            ...ConfirmDialogState,
            open: false,
            loading: false,
          });
          history.push("/admin/confirmands");
        } catch (error) {
          setConfirmDialogState({
            ...ConfirmDialogState,
            open: true,
            loading: false,
          });
          setErrorState({
            open: true,
            message: `${error}. Intente de nuevo.`,
          });
        }
      },
    });
  };

  const editPermission =
    currentUser.claims.permissions.includes("change_all_level_confirmands") ||
    (currentUser.claims.permissions.includes("change_confirmand") &&
      ConfirmandState.confirmation_group === currentUser.claims.group);
  const deletePermission =
    currentUser.claims.permissions.includes("delete_confirmand");

  if (InitialLoadingState) return <Loading />;
  if (LoadErrorState.status)
    return <Error errorMessage={LoadErrorState.message} />;

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: theme.palette.primary.light,
            borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%",
          }}
        >
          <DisplayEditAvatar
            userUid={ConfirmandState.user_uid}
            imageUrl={ConfirmandState.imageUrl}
            token={currentUser.token}
            disabled
          />
          <Typography variant="h1">{`${ConfirmandState.first_name} ${ConfirmandState.last_name}`}</Typography>
        </Paper>
      </Grid>
      {/* Asistencia */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection
            title="Asistencia"
            loading={AttendanceLoadingState}
            onClick={onAttendanceClick}
            defaultExpanded={false}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper elevation={0} style={{ padding: "3%" }}>
                  <Typography variant="h3" paddingBottom="1%">
                    Catequesis
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Legend verticalAlign="bottom" />
                      <Pie
                        data={ConfirmandState.catechesisAttendance}
                        legendType="line"
                        innerRadius={50}
                        dataKey="value"
                        nameKey="name"
                        label
                      >
                        <Cell fill={theme.palette.success.dark} />
                        <Cell fill={theme.palette.error.dark} />
                        <Cell fill={theme.palette.info.dark} />
                        <Cell fill={theme.palette.warning.dark} />
                        <Cell fill={theme.palette.warning.main} />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={0} style={{ padding: "3%" }}>
                  <Typography variant="h3" paddingBottom="1%">
                    Misas
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Legend verticalAlign="bottom" />
                      <Pie
                        data={ConfirmandState.massAttendance}
                        legendType="line"
                        innerRadius={50}
                        dataKey="value"
                        nameKey="name"
                        label
                      >
                        <Cell fill={theme.palette.success.dark} />
                        <Cell fill={theme.palette.error.dark} />
                        <Cell fill={theme.palette.info.dark} />
                        <Cell fill={theme.palette.warning.dark} />
                        <Cell fill={theme.palette.warning.main} />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={0} style={{ padding: "1%" }}>
                  <Typography variant="h3" padding="0 0 2%">
                    Listado de asistencias
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Tipo</TableCell>
                          <TableCell>Fecha</TableCell>
                          <TableCell>Asistencia</TableCell>
                          <TableCell>Comentarios</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {AttendanceState.map((attendance) => (
                          <TableRow key={attendance.id}>
                            <TableCell>{attendance.activity_type}</TableCell>
                            <TableCell>
                              {format(attendance.date, "dd 'de' MMMM", {
                                locale: es,
                              })}
                            </TableCell>
                            <TableCell
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ListItemIcon>
                                {switchIcons(attendance.attendance)}
                              </ListItemIcon>
                              {getLabel(attendanceTypes, attendance.attendance)}
                            </TableCell>
                            <TableCell width="50%">
                              {attendance.comments}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Confirmando  */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Confirmando">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { first_name: value },
                    })
                  }
                  type="text"
                  label="Nombre"
                  defaultValue={ConfirmandState.first_name}
                  name="first_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { second_name: value },
                    })
                  }
                  type="text"
                  label="Segundo Nombre"
                  defaultValue={ConfirmandState.second_name}
                  name="second_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { last_name: value },
                    })
                  }
                  type="text"
                  label="Apellido"
                  defaultValue={ConfirmandState.last_name}
                  name="last_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { second_last_name: value },
                    })
                  }
                  type="text"
                  label="Segundo Apellido"
                  defaultValue={ConfirmandState.second_last_name}
                  name="second_last_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { email: value },
                    })
                  }
                  type="email"
                  label="Correo electrónico"
                  defaultValue={ConfirmandState.email}
                  name="email"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { cellphone: value },
                    })
                  }
                  type="text"
                  label="Celular"
                  defaultValue={ConfirmandState.cellphone}
                  name="cellphone"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { address: value },
                    })
                  }
                  type="text"
                  label="Dirección"
                  defaultValue={ConfirmandState.address}
                  name="address"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                <DisplayDatePicker
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { birthdate: value },
                    })
                  }
                  label="Fecha de nacimiento"
                  loading={InitialLoadingState}
                  defaultValue={ConfirmandState.birthdate}
                  name="birthdate"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4} lg={3} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { school: value },
                    })
                  }
                  options={schools}
                  label="Colegio"
                  defaultValue={ConfirmandState.school}
                  name="school"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={6} sm={2} md={2} lg={3} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { tshirt_size: value },
                    })
                  }
                  options={tshirtSizes}
                  label="Talla"
                  defaultValue={ConfirmandState.tshirt_size}
                  name="tshirt_size"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { sex: value },
                    })
                  }
                  options={sexs}
                  label="Sexo"
                  defaultValue={ConfirmandState.sex}
                  name="sex"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { confirmation_group: value },
                    })
                  }
                  options={groups[currentUser.claims.level]}
                  label="Grupo"
                  defaultValue={ConfirmandState.confirmation_group}
                  name="confirmation_group"
                  uid={id}
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { legal_id: value },
                    })
                  }
                  type="text"
                  label="Cédula"
                  defaultValue={ConfirmandState.legal_id}
                  name="legal_id"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={8} sm={6} md={4} lg={3}>
                <DisplayCheckField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { is_fee_paid: value },
                    })
                  }
                  defaultValue={ConfirmandState.is_fee_paid}
                  label="Canceló la cuota"
                  name="is_fee_paid"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Acudientes */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Acudientes">
            <Grid container spacing={3}>
              <Grid item xs={12} display="flex" justifyContent="end">
                <Button
                  variant="contained"
                  onClick={() => setNewGuardianState(true)}
                >
                  NUEVO ACUDIENTE
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" paddingBottom="2%">
                  Madre biológica
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.mother.user_id,
                          data: { first_name: value },
                        })
                      }
                      type="text"
                      label="Primer nombre"
                      defaultValue={
                        ConfirmandState.biologicalParents.mother.first_name
                      }
                      name="first_name"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "mother",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.mother.user_id,
                          data: { second_name: value },
                        })
                      }
                      type="text"
                      label="Segundo nombre"
                      defaultValue={
                        ConfirmandState.biologicalParents.mother.second_name
                      }
                      name="second_name"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "mother",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.mother.user_id,
                          data: { last_name: value },
                        })
                      }
                      type="text"
                      label="Apellido"
                      defaultValue={
                        ConfirmandState.biologicalParents.mother.last_name
                      }
                      name="last_name"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "mother",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.mother.user_id,
                          data: { second_last_name: value },
                        })
                      }
                      type="text"
                      label="Segundo apellido"
                      defaultValue={
                        ConfirmandState.biologicalParents.mother
                          .second_last_name
                      }
                      name="second_last_name"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "mother",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.mother.user_id,
                          data: { cellphone: value },
                        })
                      }
                      type="tel"
                      label="Celular"
                      defaultValue={
                        ConfirmandState.biologicalParents.mother.cellphone
                      }
                      name="cellphone"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "mother",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayAutoComplete
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.mother.user_id,
                          data: { sex: value },
                        })
                      }
                      options={sexs}
                      label="Sexo"
                      defaultValue={
                        ConfirmandState.biologicalParents.mother.sex
                      }
                      name="sex"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "mother",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.mother.user_id,
                          data: { email: value },
                        })
                      }
                      type="email"
                      label="Correo electrónico"
                      defaultValue={
                        ConfirmandState.biologicalParents.mother.email
                      }
                      name="email"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "mother",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayAutoComplete
                      options={relationships}
                      label="Relación"
                      defaultValue={"mother"}
                      name="relationship"
                      onSave={() => {}}
                      patch={() => {}}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" paddingBottom="2%">
                  Padre biológico
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.father.user_id,
                          data: { first_name: value },
                        })
                      }
                      type="text"
                      label="Primer nombre"
                      defaultValue={
                        ConfirmandState.biologicalParents.father.first_name
                      }
                      name="first_name"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "father",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.father.user_id,
                          data: { second_name: value },
                        })
                      }
                      type="text"
                      label="Segundo nombre"
                      defaultValue={
                        ConfirmandState.biologicalParents.father.second_name
                      }
                      name="second_name"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "father",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.father.user_id,
                          data: { last_name: value },
                        })
                      }
                      type="text"
                      label="Apellido"
                      defaultValue={
                        ConfirmandState.biologicalParents.father.last_name
                      }
                      name="last_name"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "father",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.father.user_id,
                          data: { second_last_name: value },
                        })
                      }
                      type="text"
                      label="Segundo apellido"
                      defaultValue={
                        ConfirmandState.biologicalParents.father
                          .second_last_name
                      }
                      name="second_last_name"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "father",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.father.user_id,
                          data: { cellphone: value },
                        })
                      }
                      type="tel"
                      label="Celular"
                      defaultValue={
                        ConfirmandState.biologicalParents.father.cellphone
                      }
                      name="cellphone"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "father",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayAutoComplete
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.father.user_id,
                          data: { sex: value },
                        })
                      }
                      options={sexs}
                      label="Sexo"
                      defaultValue={
                        ConfirmandState.biologicalParents.father.sex
                      }
                      name="sex"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "father",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayEditField
                      patch={(value) =>
                        patchUser({
                          token: currentUser.token,
                          id: ConfirmandState.biologicalParents.father.user_id,
                          data: { email: value },
                        })
                      }
                      type="email"
                      label="Correo electrónico"
                      defaultValue={
                        ConfirmandState.biologicalParents.father.email
                      }
                      name="email"
                      onSave={(data) =>
                        onBiologicalParentEditSave({
                          data: data,
                          relationship: "father",
                        })
                      }
                      disabled={!editPermission}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DisplayAutoComplete
                      options={relationships}
                      label="Relación"
                      defaultValue={"father"}
                      name="relationship"
                      onSave={() => {}}
                      patch={() => {}}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              {Object.entries(ConfirmandState.remainingRelationships).map(
                ([guardian_id, guardian], index) => (
                  <Grid item xs={12} key={guardian_id}>
                    <Typography variant="h4" paddingBottom="2%">
                      Acudiente {index + 1}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <DisplayEditField
                          patch={(value) =>
                            patchUser({
                              token: currentUser.token,
                              id: guardian.user_id,
                              data: { first_name: value },
                            })
                          }
                          type="text"
                          label="Primer nombre"
                          defaultValue={guardian.first_name}
                          name="first_name"
                          onSave={(data) =>
                            onGuardianEditSave({
                              data: data,
                              guardian_id: guardian_id,
                            })
                          }
                          disabled={!editPermission}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <DisplayEditField
                          patch={(value) =>
                            patchUser({
                              token: currentUser.token,
                              id: guardian.user_id,
                              data: { second_name: value },
                            })
                          }
                          type="text"
                          label="Segundo nombre"
                          defaultValue={guardian.second_name}
                          name="second_name"
                          onSave={(data) =>
                            onGuardianEditSave({
                              data: data,
                              guardian_id: guardian_id,
                            })
                          }
                          disabled={!editPermission}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <DisplayEditField
                          patch={(value) =>
                            patchUser({
                              token: currentUser.token,
                              id: guardian.user_id,
                              data: { last_name: value },
                            })
                          }
                          type="text"
                          label="Apellido"
                          defaultValue={guardian.last_name}
                          name="last_name"
                          onSave={(data) =>
                            onGuardianEditSave({
                              data: data,
                              guardian_id: guardian_id,
                            })
                          }
                          disabled={!editPermission}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <DisplayEditField
                          patch={(value) =>
                            patchUser({
                              token: currentUser.token,
                              id: guardian.user_id,
                              data: {
                                second_last_name: value,
                              },
                            })
                          }
                          type="text"
                          label="Segundo apellido"
                          defaultValue={guardian.second_last_name}
                          name="second_last_name"
                          onSave={(data) =>
                            onGuardianEditSave({
                              data: data,
                              guardian_id: guardian_id,
                            })
                          }
                          disabled={!editPermission}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <DisplayEditField
                          patch={(value) =>
                            patchUser({
                              token: currentUser.token,
                              id: guardian.user_id,
                              data: { cellphone: value },
                            })
                          }
                          type="tel"
                          label="Celular"
                          defaultValue={guardian.cellphone}
                          name="cellphone"
                          onSave={(data) =>
                            onGuardianEditSave({
                              data: data,
                              guardian_id: guardian_id,
                            })
                          }
                          disabled={!editPermission}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <DisplayAutoComplete
                          patch={(value) =>
                            patchUser({
                              token: currentUser.token,
                              id: guardian.user_id,
                              data: { sex: value },
                            })
                          }
                          options={sexs}
                          label="Sexo"
                          defaultValue={guardian.sex}
                          name="sex"
                          onSave={(data) =>
                            onGuardianEditSave({
                              data: data,
                              guardian_id: guardian_id,
                            })
                          }
                          disabled={!editPermission}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <DisplayEditField
                          patch={(value) =>
                            patchUser({
                              token: currentUser.token,
                              id: guardian.user_id,
                              data: { email: value },
                            })
                          }
                          type="email"
                          label="Correo electrónico"
                          defaultValue={guardian.email}
                          name="email"
                          onSave={(data) =>
                            onGuardianEditSave({
                              data: data,
                              guardian_id: guardian_id,
                            })
                          }
                          disabled={!editPermission}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <DisplayAutoComplete
                          patch={(value) =>
                            patchRelationship({
                              token: currentUser.token,
                              id: guardian.relationship_id,
                              data: { relationship: value },
                            })
                          }
                          options={relationships.filter(
                            (relationship) =>
                              !["mother", "father"].includes(
                                relationship.value,
                              ),
                          )}
                          label="Relación"
                          defaultValue={guardian.relationship}
                          name="relationship"
                          onSave={(data) =>
                            onGuardianEditSave({
                              data: data,
                              guardian_id: guardian_id,
                            })
                          }
                          disabled={!editPermission}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={12}
                        display="flex"
                        justifyContent="end"
                        alignItems="end"
                      >
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() =>
                            handleDeleteRelationship({
                              relationship_id: guardian.relationship_id,
                              guardian: guardian,
                            })
                          }
                        >
                          DESVINCULAR ACUDIENTE
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ),
              )}
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Vida sacramental */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Vida sacramental">
            <Grid container spacing={5}>
              {/* Bautismo */}
              <Grid item sm={12} md={6} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">Certificado de Bautismo</Typography>
                </Grid>
                <Grid item xs={12}>
                  <DisplayEditImage
                    disabled={!editPermission}
                    defaultImageURL={ConfirmandState.baptism_certificate_link}
                    saveImage={(image) =>
                      updateUserBaptismCertificateStorage(
                        image,
                        ConfirmandState.user_uid,
                      )
                    }
                  />
                </Grid>
              </Grid>
              {/* Primera comunión */}
              <Grid item sm={12} md={6} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">Primera comunión</Typography>
                </Grid>
                <Grid item xs={12}>
                  <DisplayCheckField
                    patch={(value) =>
                      patchConfirmand({
                        token: currentUser.token,
                        id: ConfirmandState.id,
                        data: {
                          is_first_communion_done: value,
                        },
                      })
                    }
                    label="Recibió el sacramento"
                    defaultValue={ConfirmandState.is_first_communion_done}
                    name="is_first_communion_done"
                    onSave={onEditSave}
                    disabled={!editPermission}
                  />
                </Grid>
              </Grid>
              {/* Confirmacion */}
              <Grid item sm={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Padrino o Madrina de Confirmación
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <DisplayEditField
                    patch={(value) =>
                      patchConfirmand({
                        token: currentUser.token,
                        id: ConfirmandState.id,
                        data: {
                          confirmation_godparent_first_name: value,
                        },
                      })
                    }
                    type="text"
                    label="Nombre"
                    defaultValue={
                      ConfirmandState.confirmation_godparent_first_name
                    }
                    name="confirmation_godparent_first_name"
                    onSave={onEditSave}
                    disabled={!editPermission}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DisplayEditField
                    patch={(value) =>
                      patchConfirmand({
                        token: currentUser.token,
                        id: ConfirmandState.id,
                        data: {
                          confirmation_godparent_second_name: value,
                        },
                      })
                    }
                    type="text"
                    label="Segundo nombre"
                    defaultValue={
                      ConfirmandState.confirmation_godparent_second_name
                    }
                    name="confirmation_godparent_second_name"
                    onSave={onEditSave}
                    disabled={!editPermission}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DisplayEditField
                    patch={(value) =>
                      patchConfirmand({
                        token: currentUser.token,
                        id: ConfirmandState.id,
                        data: {
                          confirmation_godparent_last_name: value,
                        },
                      })
                    }
                    type="text"
                    label="Apellido"
                    defaultValue={
                      ConfirmandState.confirmation_godparent_last_name
                    }
                    name="confirmation_godparent_last_name"
                    onSave={onEditSave}
                    disabled={!editPermission}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DisplayEditField
                    patch={(value) =>
                      patchConfirmand({
                        token: currentUser.token,
                        id: ConfirmandState.id,
                        data: {
                          confirmation_godparent_second_last_name: value,
                        },
                      })
                    }
                    type="text"
                    label="Segundo apellido"
                    defaultValue={
                      ConfirmandState.confirmation_godparent_second_last_name
                    }
                    name="confirmation_godparent_second_last_name"
                    onSave={onEditSave}
                    disabled={!editPermission}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Salud */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Salud">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { allergies: value },
                    })
                  }
                  type="text"
                  label="Alergias"
                  multiline
                  defaultValue={ConfirmandState.allergies}
                  name="allergies"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { medications: value },
                    })
                  }
                  type="text"
                  label="Medicamentos"
                  multiline
                  defaultValue={ConfirmandState.medications}
                  name="medications"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { conditions: value },
                    })
                  }
                  type="text"
                  label="Condiciones"
                  multiline
                  defaultValue={ConfirmandState.conditions}
                  name="conditions"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Comentarios */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Comentarios">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { comments: value },
                    })
                  }
                  type="text"
                  label="Comentarios"
                  multiline
                  defaultValue={ConfirmandState.comments}
                  name="comments"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {deletePermission && (
        <Grid item xs={12} display="flex" justifyContent="end">
          <Button
            variant="contained"
            color="error"
            name="delete"
            onClick={handleDeleteUser}
          >
            ELIMINAR CONFIRMANDO
          </Button>
        </Grid>
      )}
      <NewGuardianModal
        open={NewGuardianState}
        onClose={({ newGuardian, canceled }) =>
          onNewGuardianClose({
            newGuardian,
            canceled,
          })
        }
        confirmand_id={ConfirmandState.id}
        currentRelationships={ConfirmandState.confirmandGuardiansId}
      />
      <ConfirmDialog
        open={ConfirmDialogState.open}
        loading={ConfirmDialogState.loading}
        title={ConfirmDialogState.title}
        description={ConfirmDialogState.description}
        onConfirm={ConfirmDialogState.onConfirm}
        onClose={() =>
          setConfirmDialogState({
            open: false,
            loading: false,
          })
        }
        confirmText={ConfirmDialogState.confirmText}
        confirmColor="error"
        cancelText={ConfirmDialogState.cancelText}
      />
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

export default AdminConfirmand;
