import { useContext } from "react";
import UserContext from "assets/plugins/contexts/userContext";

const useUser = () => {
  const { currentUser, pending } = useContext(UserContext);

  return {
    currentUser,
    pending,
  };
};

export default useUser;
