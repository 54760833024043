// Prop Types
import PropTypes from "prop-types";

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Zoom,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const ConfirmDialog = ({
  open,
  loading,
  onClose,
  onConfirm,
  title,
  description,
  confirmText,
  cancelText,
  confirmColor,
}) => {
  return (
    <Dialog open={open || loading} onClose={onClose} TransitionComponent={Zoom}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="black">
          {cancelText}
        </Button>
        <LoadingButton
          name="confirm"
          onClick={onConfirm}
          autoFocus
          loading={loading}
          variant="contained"
          color={confirmColor}
        >
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmColor: PropTypes.string.isRequired,
};

export default ConfirmDialog;
