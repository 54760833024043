// Material
import { Box, Button } from "@mui/material";

// Routing
import { Redirect } from "react-router-dom";

// Current user
import useUser from "assets/hooks/useUser";

// Log
import { logger } from "assets/plugins/log";

const background = require("assets/images/background.svg").default;

const Home = () => {
  const { currentUser } = useUser();

  if (!currentUser) {
    logger("User not logged in.", { source: "/" });
    return <Redirect to="/auth/login" />;
  } else if (currentUser.claims.user_type === "catechist") {
    logger("Catechist already logged in.", {
      source: "/",
      payload: { currentUser },
    });
    return <Redirect to="/admin/dashboard" />;
  } else if (currentUser.claims.user_type === "confirmand") {
    logger("Confirmand already logged in.", {
      source: "/",
      payload: { currentUser },
    });
    return <Redirect to="/confirmand/dashboard" />;
  }

  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Button
        color="secondary"
        variant="contained"
        onClick={() => history.push("/auth/login")}
      >
        INGRESAR
      </Button>
    </Box>
  );
};

export default Home;
