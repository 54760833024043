// React
import { useEffect, useState } from "react";

// Constants
import {
  sexs,
  tshirtSizes,
  groups,
  teams,
  attendanceTypes,
} from "assets/constants";

// Current User
import useUser from "assets/hooks/useUser";

// Material
import {
  Autocomplete,
  Button,
  Grid,
  Typography,
  Paper,
  ListItemIcon,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TextField,
} from "@mui/material";

// Routing
import { useParams, useHistory } from "react-router-dom";

// Theming
import theme from "theme";

// Axios
import {
  catechist,
  attendances,
  patchUser,
  patchCatechist,
  deleteUser,
  permissionGroups,
} from "assets/plugins/axios";

// Components
import Loading from "components/Loading";
import DisplayEditField from "components/DisplayEditField";
import DisplayAutoComplete from "components/DisplayAutoComplete";
import DisplayDatePicker from "components/DisplayDatePicker";
import DisplayEditAvatar from "components/DisplayEditAvatar";
import CollapseSection from "components/CollapseSection";
import Alert from "components/Alert";
import Error from "components/Error";
import ConfirmDialog from "components/ConfirmDialog";

// Functions
import { getLabel, switchIcons } from "assets/functions";

// Date fns
import { parse, format } from "date-fns";
import { es } from "date-fns/locale";

// Chart
import { PieChart, Pie, ResponsiveContainer, Legend, Cell } from "recharts";

// Log
import { logger } from "assets/plugins/log";

const AdminCatechist = () => {
  const { id } = useParams();
  const history = useHistory();

  const { currentUser } = useUser();

  const editPermission =
    currentUser.claims.permissions.includes("change_catechist");
  const deletePermission =
    currentUser.claims.permissions.includes("delete_catechist");
  const editPermissionGroupPermission =
    currentUser.claims.permissions.includes("change_levelgroup");

  const [CatechistState, setCatechistState] = useState({});
  const [GroupPermissionsState, setGroupPermissionsState] = useState([]);
  const [GroupPermissionOptionsState, setGroupPermissionOptionsState] =
    useState([]);
  const [InitialLoadingState, setInitialLoadingState] = useState(true);
  const [LoadErrorState, setLoadErrorState] = useState({
    status: false,
    message: "",
  });
  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });
  const [ConfirmDialogState, setConfirmDialogState] = useState({
    open: false,
    loading: false,
    title: "",
    description: "",
    confirmText: "",
    cancelText: "",
    onConfirm: () => {},
  });
  const [AttendanceLoadingState, setAttendanceLoadingState] = useState(false);
  const [AttendanceState, setAttendanceState] = useState([]);

  const fieldsMap = {
    id: {
      fieldName: "id",
      expand: "",
    },
    birthdate: {
      fieldName: "birthdate",
      expand: "",
    },
    tshirt_size: {
      fieldName: "tshirt_size",
      expand: "",
    },
    "confirmation_group.id": {
      fieldName: "confirmation_group.id",
      expand: "confirmation_group",
    },
    "confirmation_group.group": {
      fieldName: "confirmation_group.group",
      expand: "confirmation_group",
    },
    team: {
      fieldName: "team",
      expand: "",
    },
    "user.id": {
      fieldName: "user.id",
      expand: "user",
    },
    "user.uid": {
      fieldName: "user.uid",
      expand: "user",
    },
    "user.email": {
      fieldName: "user.email",
      expand: "user",
    },
    "user.first_name": {
      fieldName: "user.first_name",
      expand: "user",
    },
    "user.second_name": {
      fieldName: "user.second_name",
      expand: "user",
    },
    "user.last_name": {
      fieldName: "user.last_name",
      expand: "user",
    },
    "user.second_last_name": {
      fieldName: "user.second_last_name",
      expand: "user",
    },
    "user.cellphone": {
      fieldName: "user.cellphone",
      expand: "user",
    },
    "user.sex": {
      fieldName: "user.sex",
      expand: "user",
    },
    "user.groups": {
      fieldName: "user.groups",
      expand: "user",
    },
    mass_attendance: {
      fieldName: "mass_attendance",
      expand: "",
    },
    catechesis_attendance: {
      fieldName: "catechesis_attendance",
      expand: "",
    },
    meeting_attendance: {
      fieldName: "meeting_attendance",
      expand: "",
    },
  };

  const initialFields = [];
  const initialExpand = [];

  for (const field of Object.values(fieldsMap)) {
    initialFields.push(field.fieldName);
    if (!initialExpand.includes(field.expand)) {
      initialExpand.push(field.expand);
    }
  }

  const params = {
    id,
    fields: initialFields.toString(),
    expand: initialExpand.toString(),
  };

  useEffect(() => {
    const getCatechist = async () => {
      try {
        const { data } = await catechist({
          token: currentUser.token,
          params,
        });

        const { data: groupData } = await permissionGroups({
          token: currentUser.token,
        });

        setGroupPermissionOptionsState(
          groupData.results.filter(
            (group) => group.level_group.is_catechist_group,
          ),
        );

        const catechesisAttendance = Object.entries(data.catechesis_attendance)
          .map(([key, value]) => {
            return {
              name: getLabel(attendanceTypes, key),
              value: value,
            };
          })
          .sort((a, b) => (b.name > a.name ? -1 : 1));

        const massAttendance = Object.entries(data.mass_attendance)
          .map(([key, value]) => {
            return {
              name: getLabel(attendanceTypes, key),
              value: value,
            };
          })
          .sort((a, b) => (b.name > a.name ? -1 : 1));

        const meetingAttendance = Object.entries(data.meeting_attendance)
          .map(([key, value]) => {
            return {
              name: getLabel(attendanceTypes, key),
              value: value,
            };
          })
          .sort((a, b) => (b.name > a.name ? -1 : 1));

        const userGroups = groupData.results.filter((userGroup) =>
          data.user.groups.includes(userGroup.id),
        );

        setCatechistState({
          user_uid: data.user.uid,
          user_id: data.user.id,
          id: data.id,
          first_name: data.user.first_name,
          second_name: data.user.second_name,
          last_name: data.user.last_name,
          second_last_name: data.user.second_last_name,
          email: data.user.email,
          cellphone: data.user.cellphone,
          sex: data.user.sex,
          birthdate: parse(data.birthdate, "yyyy-MM-dd", new Date()),
          tshirt_size: data.tshirt_size,
          confirmation_group: data.confirmation_group.id,
          team: data.team,
          permissions: data.user.user_permissions,
          catechesis_attendance: catechesisAttendance,
          mass_attendance: massAttendance,
          meeting_attendance: meetingAttendance,
          imageUrl: `https://api.dicebear.com/7.x/bottts/svg?seed=${data.user.uid}`,
        });
        setGroupPermissionsState(userGroups);
      } catch (error) {
        setLoadErrorState({
          status: true,
          message: error.message,
        });
      } finally {
        setInitialLoadingState(false);
      }
    };

    getCatechist();
    logger("[Admin Catechist] page loaded.", {
      payload: { currentUser },
      source: `/admin/catechist/${id}`,
    });
  }, [id, currentUser.token]);

  const onEditSave = (data) => {
    setCatechistState({
      ...CatechistState,
      ...data,
    });
  };

  const handleDeleteUser = () => {
    const catechistName =
      `${CatechistState.first_name} ${CatechistState.second_name} ${CatechistState.second_last_name} ${CatechistState.second_last_name}`
        .trim()
        .replace("  ", " ");
    setConfirmDialogState({
      open: true,
      loading: false,
      title: "Eliminar catequista",
      description: `¿Está seguro que desea eliminar al catequista ${catechistName}?`,
      confirmText: "ELIMINAR",
      cancelText: "CANCELAR",
      onConfirm: async () => {
        setConfirmDialogState({
          ...ConfirmDialogState,
          loading: true,
        });
        try {
          await deleteUser({
            token: currentUser.token,
            id: CatechistState.user_id,
          });
          setConfirmDialogState({
            ...ConfirmDialogState,
            open: false,
            loading: false,
          });
          history.push("/admin/catechists");
        } catch (error) {
          setConfirmDialogState({
            ...ConfirmDialogState,
            open: true,
            loading: false,
          });
        }
      },
    });
  };

  const onAttendanceClick = async () => {
    try {
      setAttendanceLoadingState(true);
      const { data } = await attendances({
        token: currentUser.token,
        params: {
          search: CatechistState.user_id,
          fields:
            "id,attendance,comments,activity.date,activity.activity_type,activity.id",
          expand: "activity",
        },
      });
      const formattedAttendances = data.results
        .map((attendance) => {
          return {
            id: attendance.id,
            date: parse(
              attendance.activity.date,
              "yyyy-MM-dd'T'HH:mm:ssxxx",
              new Date(),
            ),
            activity_type: attendance.activity.activity_type,
            attendance: attendance.attendance,
            comments: attendance.comments,
          };
        })
        .sort((a, b) => (b.date > a.date ? -1 : 1));
      setAttendanceState(formattedAttendances);
    } catch (error) {
      setErrorState({
        open: true,
        message: `${error}. Intente de nuevo.`,
      });
    } finally {
      setAttendanceLoadingState(false);
    }
  };

  const onPermissionGroupChange = async (_, value) => {
    const groups = value.map((group) => group.id);
    await patchUser({
      token: currentUser.token,
      id: CatechistState.user_id,
      data: { groups },
    });
    setGroupPermissionsState(value);
  };

  if (InitialLoadingState) return <Loading />;
  if (LoadErrorState.status)
    return <Error errorMessage={LoadErrorState.message} />;

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: theme.palette.primary.light,
            borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%",
          }}
        >
          <DisplayEditAvatar
            userUid={CatechistState.user_uid}
            imageUrl={CatechistState.imageUrl}
            token={currentUser.token}
            disabled
          />
          <Typography variant="h1">{`${CatechistState.first_name} ${CatechistState.last_name}`}</Typography>
        </Paper>
      </Grid>
      {/* Asistencia */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection
            title="Asistencia"
            loading={AttendanceLoadingState}
            onClick={onAttendanceClick}
            defaultExpanded={false}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <Paper elevation={0} style={{ padding: "3%" }}>
                  <Typography variant="h3" paddingBottom="1%">
                    Catequesis
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Legend verticalAlign="bottom" />
                      <Pie
                        data={CatechistState.catechesis_attendance}
                        legendType="line"
                        innerRadius={50}
                        dataKey="value"
                        nameKey="label"
                        label
                      >
                        <Cell fill={theme.palette.success.dark} />
                        <Cell fill={theme.palette.error.dark} />
                        <Cell fill={theme.palette.info.dark} />
                        <Cell fill={theme.palette.warning.dark} />
                        <Cell fill={theme.palette.warning.main} />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Paper elevation={0} style={{ padding: "3%" }}>
                  <Typography variant="h3" paddingBottom="1%">
                    Reuniones
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Legend verticalAlign="bottom" />
                      <Pie
                        data={CatechistState.meeting_attendance}
                        legendType="line"
                        innerRadius={50}
                        dataKey="value"
                        nameKey="label"
                        label
                      >
                        <Cell fill={theme.palette.success.dark} />
                        <Cell fill={theme.palette.error.dark} />
                        <Cell fill={theme.palette.info.dark} />
                        <Cell fill={theme.palette.warning.dark} />
                        <Cell fill={theme.palette.warning.main} />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Paper elevation={0} style={{ padding: "3%" }}>
                  <Typography variant="h3" paddingBottom="1%">
                    Misa
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Legend verticalAlign="bottom" />
                      <Pie
                        data={CatechistState.mass_attendance}
                        legendType="line"
                        innerRadius={50}
                        dataKey="value"
                        nameKey="label"
                        label
                      >
                        <Cell fill={theme.palette.success.dark} />
                        <Cell fill={theme.palette.error.dark} />
                        <Cell fill={theme.palette.info.dark} />
                        <Cell fill={theme.palette.warning.dark} />
                        <Cell fill={theme.palette.warning.main} />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={0} style={{ padding: "1%" }}>
                  <Typography variant="h3" padding="0 0 2%">
                    Listado de asistencias
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Tipo</TableCell>
                          <TableCell>Fecha</TableCell>
                          <TableCell>Asistencia</TableCell>
                          <TableCell>Comentarios</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {AttendanceState.map((attendance) => (
                          <TableRow key={attendance.id}>
                            <TableCell>{attendance.activity_type}</TableCell>
                            <TableCell>
                              {format(attendance.date, "dd 'de' MMMM", {
                                locale: es,
                              })}
                            </TableCell>
                            <TableCell
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ListItemIcon>
                                {switchIcons(attendance.attendance)}
                              </ListItemIcon>
                              {getLabel(attendanceTypes, attendance.attendance)}
                            </TableCell>
                            <TableCell width="50%">
                              {attendance.comments}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Catequista  */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Catequista">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: CatechistState.user_id,
                      data: { first_name: value },
                    })
                  }
                  type="text"
                  label="Nombre"
                  defaultValue={CatechistState.first_name}
                  name="first_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: CatechistState.user_id,
                      data: { second_name: value },
                    })
                  }
                  type="text"
                  label="Segundo Nombre"
                  defaultValue={CatechistState.second_name}
                  name="second_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: CatechistState.user_id,
                      data: { last_name: value },
                    })
                  }
                  type="text"
                  label="Apellido"
                  defaultValue={CatechistState.last_name}
                  name="last_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: CatechistState.user_id,
                      data: { second_last_name: value },
                    })
                  }
                  type="text"
                  label="Segundo Apellido"
                  defaultValue={CatechistState.second_last_name}
                  name="second_last_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: CatechistState.user_id,
                      data: { email: value },
                    })
                  }
                  type="email"
                  label="Correo electrónico"
                  defaultValue={CatechistState.email}
                  name="email"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: CatechistState.user_id,
                      data: { cellphone: value },
                    })
                  }
                  type="text"
                  label="Celular"
                  defaultValue={CatechistState.cellphone}
                  name="cellphone"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                <DisplayDatePicker
                  patch={(value) =>
                    patchCatechist({
                      token: currentUser.token,
                      id: CatechistState.id,
                      data: { birthdate: value },
                    })
                  }
                  label="Fecha de nacimiento"
                  loading={InitialLoadingState}
                  defaultValue={CatechistState.birthdate}
                  name="birthdate"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchCatechist({
                      token: currentUser.token,
                      id: CatechistState.id,
                      data: { team: value },
                    })
                  }
                  options={teams}
                  label="Comisión"
                  defaultValue={CatechistState.team}
                  name="team"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={6} sm={2} md={2} lg={3} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchCatechist({
                      token: currentUser.token,
                      id: CatechistState.id,
                      data: { tshirt_size: value },
                    })
                  }
                  options={tshirtSizes}
                  label="Talla"
                  defaultValue={CatechistState.tshirt_size}
                  name="tshirt_size"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={6} sm={2} md={2} lg={3} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchCatechist({
                      token: currentUser.token,
                      id: CatechistState.id,
                      data: { sex: value },
                    })
                  }
                  options={sexs}
                  label="Sexo"
                  defaultValue={CatechistState.sex}
                  name="sex"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={4} sm={2} md={4} lg={3} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchCatechist({
                      token: currentUser.token,
                      id: CatechistState.id,
                      data: { confirmation_group: value },
                    })
                  }
                  options={groups[currentUser.claims.level]}
                  label="Grupo"
                  defaultValue={CatechistState.confirmation_group}
                  name="confirmation_group"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Permisos  */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Permisos">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  disabled={!editPermissionGroupPermission}
                  options={GroupPermissionOptionsState}
                  getOptionLabel={(option) => option.level_group.name}
                  value={GroupPermissionsState}
                  onChange={onPermissionGroupChange}
                  autoHighlight
                  fullWidth
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Grupo de permisos"
                      name="permission_groups"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {deletePermission && (
        <Grid item xs={12} display="flex" justifyContent="end">
          <Button variant="contained" color="error" onClick={handleDeleteUser}>
            ELIMINAR CATEQUISTA
          </Button>
        </Grid>
      )}
      <ConfirmDialog
        open={ConfirmDialogState.open}
        loading={ConfirmDialogState.loading}
        title={ConfirmDialogState.title}
        description={ConfirmDialogState.description}
        onConfirm={ConfirmDialogState.onConfirm}
        onClose={() =>
          setConfirmDialogState({
            open: false,
            loading: false,
          })
        }
        confirmText={ConfirmDialogState.confirmText}
        confirmColor="error"
        cancelText={ConfirmDialogState.cancelText}
      />
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

export default AdminCatechist;
